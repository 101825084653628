import { Box, styled } from "@mui/material";
import React from "react";
const Vertical = styled(Box)(({ theme }) => ({
  height: "95%",
  width: "0px ",
  border: "0.1px solid grey",
  borderRadius: "25px",
  borderStyle: "ridge grey",
  transform: "scaleX(0.2)",
}));
export default function VerticalDivider() {
  return <Vertical />;
}
