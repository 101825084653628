import toast from "react-hot-toast";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";

export async function changeStatusApiCall(
  status,
  emailId,
  setSwitchStatus
  // setActiveUserAndAdminNumber
) {
  try {
    instance
      .put(
        `${API_PATH.CHANGE_LOGIN_STATUS}?emailid=${emailId}&isactive=${status}`
      )
      .then(
        (res) => {
          console.log(res, "ress");
          if (res?.IsSuccess) {
            setSwitchStatus(status);
            // setActiveUserAndAdminNumber((prev) => {
            //   if (status) {
            //     prev.Count.user = prev.Count.user + 1;
            //   } else {
            //     prev.Count.user = prev.Count.user - 1;
            //   }
            //   return { ...prev };
            // });
            toast.success(res?.message?.Message);
          } else {
            setSwitchStatus(!status);
            toast.error(res?.message?.Message);
          }
        },
        (err) => {
          console.log("user api call failed", err);
          setSwitchStatus(!status);
          toast.error("unable to change the status");
        }
      );
  } catch (err) {
    // console.log(err, "this is the error");
  }
}

// get module list
export const getModuleData = async (emailId, setDataModel, setLoading) => {
  try {
    instance
      .get(`${API_PATH.GET_MODULE}?emailid=${emailId}`)
      .then(
        (res) => {
          setDataModel(res?.data);
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  } catch (err) {
    console.log(err);
  }
};
// update data of modle access
export const putModuleData = async (body, setBackDrop, handleClose) => {
  try {
    setBackDrop(true);
    instance
      .put(`${API_PATH.GET_MODULE}`, body)
      .then(
        (res) => {
          if (res?.message?.Message == "Success!") {
            toast.success("Modules access updated");
            handleClose(true);
          } else {
            toast.success("Modules access failed to update");
          }
        },
        (err) => {
          console.log(err);
          toast.success("Modules access failed to update");
        }
      )
      .finally(() => {
        setBackDrop(false);
      });
  } catch (err) {
    console.log(err);
  }
};

// // get role list
// export const getRoleData = async (emailId, setDataRole, setLoading) => {
//   try {
//     instance
//       .get(`${API_PATH.GET_ROLE}?emailid=${emailId}`)
//       .then(
//         (res) => {
//           // delete(res?.data["EmailId"])
//           // console.log(res?.data,"module response")
//           setDataRole(res?.data);
//         },
//         (err) => {
//           console.log(err);
//         }
//       )
//       .finally(() => {
//         setLoading(false);
//       });
//   } catch (err) {
//     console.log(err);
//   }
// };

// // update data of role
// export const putRoleData = async (body) => {
//   try {
//     instance
//       .put(`${API_PATH.GET_ROLE}`, body)
//       .then(
//         (res) => {
//           console.log(res?.data, "module response");
//         },
//         (err) => {
//           console.log(err);
//         }
//       )
//       .finally(() => {});
//   } catch (err) {
//     console.log(err);
//   }
// };
