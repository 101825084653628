import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import Chip from "@mui/material/Chip";

import CloseIcon from "@mui/icons-material/Close";
import BarChart from "../../../../charts/CarrierAnalysis/BarChart";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import HalfPieChart from "../../../../charts/KpiAnalysis/HalfPieChart";
import instance from "../../../../api/Interceptor";
import { API_PATH } from "../../../../constant/apiPath";
import HalpPieChartDeparture from "../../../../charts/KpiAnalysis/HalpPieChartDeparture";
import HalpPieChartArrival from "../../../../charts/KpiAnalysis/HalfPieChartArrival";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
export default function KPIAnalysis(props) {
  console.log("filterOptions", props.filterOptions);
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [chartData, setChartData] = useState(true);
  const [inputFieldLength, setInputFieldLength] = useState(0);
  const [threshholdSettings, setThreshholdSettings] = useState([]);
  const [count, setCount] = useState(0);
  const [updateData, setUpdateData] = useState(0);
  let handleClickOpen = props.handleClickOpen;
  // const [posted, setPosted] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const postThreshholdSettings = () => {
    const body = threshholdSettings;
    instance
      .post(API_PATH.SET_THRESHOLD_SETTINGS, body)
      .then((res) => {
        console.log("SET_THRESHOLD_SETTINGS SUCCESS");
        // setPosted(true);
        setUpdateData(updateData + 1);
        setOpen(false);
        getThreshholdSettings();
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err);
      });
  };
  const getThreshholdSettings = () => {
    const body = {
      ShpEtdFrom: "",
      ShpEtdTo: "",
      ShpEtaFrom: "",
      ShpEtaTo: "",
      ShpTransportMode: props.filterOptions?.filterMode
        ? props.filterOptions.filterMode
        : "SEA",
      ShpOriginPortCode: "",
      ShpOriginPortName: "",
      ShpDestinationPortCode: "",
      ShpConsignorCode: "",
      ShpConsigneeCode: "",
      IncoTerm: "",
      ShpCarrierCode: "",
      ShpCarrierName: "",
      Year: 0,
    };
    instance
      .get(API_PATH.GET_THRESHOLD_SETTINGS, body)
      .then((res) => {
        console.log("res LIST", res?.data[0]);
        setThreshholdSettings(res?.data);
        setInputFieldLength(res?.data?.length);
        setCount(res?.data[0]?.SettingValues);
        let temp = [];

        setChartData(temp);
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err);
      });
  };
  useEffect(() => {
    getThreshholdSettings();
  }, [props.filterOptions.filterMode]);
  return (
    <Container
      maxWidth="xxl"
      sx={{
        backgroundColor: "var(--color-white)",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">KPI Analysis</Typography>
        <Tooltip title="Set Threshold Value">
          {/* <IconButton> */}
          <Chip
            style={{ margin: "12px" }}
            onClick={() => {
              setOpen(!open);
            }}
            label="Set Threshold Value"
            variant="outlined"
            icon={<AddIcon color="primary" size="large" />}
          />
          {/* </IconButton> */}
        </Tooltip>
      </div>

      <Divider />
      <Grid container spacing={4}>
        {/* Charts */}
        <Grid
          item
          lg={12}
          sx={{
            marginTop: "1rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="paper-chart">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <Typography variant="subtitle2" color={"primary"}>
                    HBLIssue Vs Shipped On
                  </Typography>
                  <IconButton size={"small"}>
                    <ZoomOutMapIcon
                      onClick={() => {
                        handleClickOpen(
                          "HBLIssue Vs Shipped On",
                          <HalfPieChart
                            filterOptions={props.filterOptions}
                            updateData={updateData}
                          />
                        );
                      }}
                    />
                  </IconButton>
                </div>
                <HalfPieChart
                  filterOptions={props.filterOptions}
                  updateData={updateData}
                />
              </div>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="paper-chart">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <Typography variant="subtitle2" color={"primary"}>
                    Departure from Port Vs Actual Pickup
                  </Typography>
                  <IconButton size={"small"}>
                    <ZoomOutMapIcon
                      onClick={() => {
                        handleClickOpen(
                          "Actual Pickup Vs Departure",
                          <HalpPieChartDeparture
                            filterOptions={props.filterOptions}
                            updateData={updateData}
                          />
                        );
                      }}
                    />
                  </IconButton>
                </div>
                <HalpPieChartDeparture
                  filterOptions={props.filterOptions}
                  updateData={updateData}
                />
              </div>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <div className="paper-chart">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <Typography variant="subtitle2" color={"primary"}>
                    Actual Delivery Vs Arrival at Port
                  </Typography>
                  <IconButton size={"small"}>
                    <ZoomOutMapIcon
                      onClick={() => {
                        handleClickOpen(
                          " Actual Delivery Vs Arrival",
                          <HalpPieChartArrival
                            filterOptions={props.filterOptions}
                            updateData={updateData}
                          />
                        );
                      }}
                    />
                  </IconButton>
                </div>
                <HalpPieChartArrival
                  filterOptions={props.filterOptions}
                  updateData={updateData}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Select</DialogTitle>
        <DialogContent
          sx={{
            padding: "4%",
          }}
        >
          <FormControl sx={{ marginTop: "2%" }} fullWidth>
            <TextField
              value={count}
              onChange={(e) => {
                let tempThresholdSettings = [...threshholdSettings];
                tempThresholdSettings.map((item, index) => {
                  tempThresholdSettings[index].SettingValues = e.target.value;
                });
                setCount(e.target.value);
              }}
              type="number"
              placeholder="Threshold Value"
              label="Threshold Value"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => postThreshholdSettings()}>
            Set Threshold Values
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
