import React from "react";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Mapcomp(props) {
  var points = [
    { lat: 42.02, lng: -77.01 },
    { lat: 42.03, lng: -77.02 },
    { lat: 41.03, lng: -77.04 },
    { lat: 42.05, lng: -77.02 },
  ];
  const triangleCoords = [
    { lat: 25.774, lng: -80.19 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
    { lat: 25.774, lng: -80.19 },
  ];

  return (
    // Important! Always set the container height explicitly
    <div className="locate-div">
      <Map
        google={props.google}
        zoom={14}
        initialCenter={{
          lat: 42.39,
          lng: -72.52,
        }}
        className="scdc"
        bounds={points}
      >
        {/* {cordinates?.location.length &&
          cordinates?.location.map((item, index) => {
            return ( */}
        <Marker
          // key={index}
          // position={{ lat: item.lat, lng: item.lng }}
          // title={item.locName}
          // name={item.locType}
          title={"The marker`s title will appear as a tooltip."}
          name={"SOMA"}
          position={{ lat: 37.778519, lng: -122.40564 }}
        />
        <Marker
          name={"Dolores park"}
          position={{ lat: 37.759703, lng: -122.428093 }}
        />
        <Marker />
        {/* );
          })} */}
        <Polyline
        // path={polylinedata}
        // strokeColor="#0000FF"
        // strokeOpacity={0.8}
        // strokeWeight={2}
        />
        <Polyline
          path={triangleCoords}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={2}
        />
      </Map>
    </div>
  );
}
// export default Map;

export default GoogleApiWrapper({
  apiKey: "AIzaSyDpfWeLTpZm0lhuv7_s-8cvKv_8sxJUHD0",
})(React.memo(Mapcomp));
