import React from "react";
import { basename } from "../Config";
import urlResolver from "../utils/urlResolver";

const NoRoute = () => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center flex-column">
      <img
        src={urlResolver(`assets/headerLogo.png`)}
        alt="company logo"
        className="mb-3"
        style={{ height: "8rem" }}
      />
      <div
        className="font-weight-bold"
        style={{ color: "var(--color-primary)" }}
      >
        No page found!
      </div>
    </div>
  );
};

export default NoRoute;
