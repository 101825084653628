import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Routeconstant } from "../utils/RouteConstant";
import { AuthContext } from "../../store/context/AuthProvider";
import { localData } from "../../services/auth-header";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";

function PublicRoute(props) {
  const context = useContext(AuthContext);
  const gettoken = localData();
  console.log(gettoken);
  useEffect(() => {
    if (gettoken && gettoken.AccessToken) {
      context.setIslogin(true);
      context.setToken(gettoken);
      const decoded = jwt_decode(gettoken.AccessToken);
      let obj = {
        Role: "",
        Name: "",
        Email: "",
      };
      if (Object.keys(decoded).length) {
        Object.keys(decoded).map((item) => {
          if (item.includes("role")) {
            obj.Role = decoded[item];
          } else if (item.includes("name")) {
            obj.Name = decoded[item];
          } else if (item.includes("emailaddress")) {
            obj.Email = decoded[item];
          }
        });
      }
      context.setUserData(obj);
    }
  }, []);

  if (gettoken && gettoken.AccessToken) {
    return <Navigate to={Routeconstant.LANDING_PAGES} replace={true} />;
  }
  return props.children;
}

export default PublicRoute;
