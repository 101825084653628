import { constants } from "buffer";
import React, { useState, createContext } from "react";
const AuthContext = createContext();

function AuthProvider(props) {
  const [isLogin, setIslogin] = useState(false);
  const [userData, setUserData] = useState({});
  const [gridViewShipment, setGridViewShipment] = useState(true);
  const [gridViewOrder, setGridViewOrder] = useState(true);
  const [shipmentCollection, setShipmentCollection] = useState([]);
  const [preloaded, setPreloaded] = useState([]);
  const [loaded, setLoaded] = useState([]);
  const [arrived, setArrived] = useState([]);
  const [archive, setArchive] = useState([]);
  const [orderCollection, setOrderCollection] = useState([]);
  const [preloadedOrder, setPreloadedOrder] = useState([]);
  const [loadedOrder, setLoadedOrder] = useState([]);
  const [arrivedOrder, setArrivedOrder] = useState([]);
  const [token, setToken] = useState({});
  const [filterLoaded, setFilterLoaded] = useState(false);
  const [buttonActive, setButtonActive] = useState({Code: "AIR", Description:"Air Freight"});
  const [rmsData, setRmsData] = useState({});
  const [rmsinquiry, setRmsinquiry] = useState("");
  const [listSize, setListSize] = useState(0);
  const [shipmentId, setShipmentId] = useState("")

  const updateUserData = (data) => {
    localStorage.setItem('userData', JSON.stringify(data));
    // Update the state with the new userData
    setUserData(data);
  };

  return (
    <AuthContext.Provider
      value={{
        listSize,
        isLogin,
        gridViewShipment,
        shipmentCollection,
        preloaded,
        loaded,
        arrived,
        gridViewOrder,
        arrivedOrder,
        loadedOrder,
        preloadedOrder,
        orderCollection,
        token,
        userData,
        archive,
        filterLoaded,
        buttonActive,
        rmsData,
        rmsinquiry,
        shipmentId,
        setListSize,
        setUserData: updateUserData,
        setPreloadedOrder,
        setOrderCollection,
        setLoadedOrder,
        setArrivedOrder,
        setIslogin,
        setGridViewShipment,
        setShipmentCollection,
        setPreloaded,
        setLoaded,
        setArrived,
        setGridViewOrder,
        setToken,
        setArchive,
        setFilterLoaded,
        setButtonActive,
        setRmsData,
        setRmsinquiry,
        setShipmentId,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
