const ENV = "PRD";
let basename = "";
let baseUrl = "";
let LOCAL_STORAGE_KEY = "";
let customerName = "1UP CARGO";
if (ENV === "PRD") {
  basename = "";
  baseUrl = "https://tracking.1upcargo.com/1UpCargoApiV2/";
  LOCAL_STORAGE_KEY = "sflinsights_mvp2";
  //  "homepage": "https://tracking.1upcargo.com/",
} else {
  basename = "1UPConnectV2";
  baseUrl = "https://dev2.sflhub.com/1UPConnectApiV2/";
  LOCAL_STORAGE_KEY = "sflinsights_mvp2";
  // "homepage": "https://dev2.sflhub.com/1UPConnectV2/",
}

export { basename, baseUrl, LOCAL_STORAGE_KEY, customerName };
