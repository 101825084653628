import React, { useEffect, useState, useRef } from "react";
import "../shipment/shipment.css";
import "./rms.css";
import {
  IconButton,
  MenuItem,
  Paper,
  Grow,
  Popper,
  MenuList,
  Menu,
  Select,
} from "@mui/material";
import { basename } from "../../Config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Fab } from "@mui/material";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import instance from "../../api/Interceptor";
import dayjs from "dayjs";
import {
  Edit,
  ChatBubbleOutline,
  NotificationsNone,
  Restore,
  Share,
} from "@mui/icons-material";

const Rms = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [getRmsData, setGetRmsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filteredDatas, setFilteredDatas] = useState([]);

  const handleInquiryClick = (inquiryNo) => {
    navigate(`${Routeconstant.RMSREPORT}/${inquiryNo}`);
    console.log(inquiryNo, "inquiryNo");
  };
  // Function to handle menu open
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const handlesearch = (e) => {
    setSearchValue(e.target.value);
  };
  useEffect(() => {
    getRmsDetails();
  }, []);

  const getRmsDetails = () => {
    setLoading(true);
    const body = {
      InquiryNo: "",
      CurrentPage: 0,
      PageSize: 150,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post("api/v1/RateManagement/GetRateCollection", body)
      .then((res) => {
        setGetRmsData(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //  const filteredData = getRmsData.filter((item) =>
  //  item.InquiryNo.includes(searchValue) ||
  //  item.OriginPort.includes(searchValue)
  // );
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <p className="layout-head mb-2 px-4">Shipments</p>
          <div className="col-md-11 col-xs-11">
            <div className="headersearch searchShipmentDiv ">
              <input
                type="text"
                className="form-control "
                placeholder="Search your Shipment"
                value={searchValue}
                onChange={handlesearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    console.log("searchhhhhh");
                    getRmsDetails();
                  }
                }}
              />
              <img
                src={`/${basename}/assets/search_icon.svg`}
                style={{ color: "rgba(var(--text-heading-dark))" }}
                className="searchIcon"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tableWrap">
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" className="font-table">
                Query Number
              </th>
              <th scope="col" className="font-table">
                Transport Mode
              </th>
              <th scope="col" className="font-table">
                Origin Port
              </th>
              <th scope="col" className="font-table">
                Destination Port
              </th>
              <th scope="col" className="font-table">
                INCO Terms
              </th>
              <th scope="col" className="font-table">
                Cargo Type
              </th>
              <th scope="col" className="font-table">
                Cargo Ready Date
              </th>
              <th scope="col" className="font-table">
                Status
              </th>
              <th scope="col" className="font-table">
                Download
              </th>
              <th scope="col" className="font-table">
                <IconButton>
                  <FilterAltOutlinedIcon
                    style={{ color: "var(--color-secondary-dark)" }}
                  />
                </IconButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {getRmsData.map((item, index) => (
              <tr key={index}>
                <td>
                  <img src={`/${basename}/assets/star.svg`} alt="" />
                </td>
                <td
                  style={{ cursor: "pointer", color: "var(--color-mile)" }}
                  onClick={() => handleInquiryClick(item.InquiryNo)}
                >
                  {" "}
                  {item.InquiryNo}
                </td>
                {/* <td style={{ cursor: "pointer", color: "var(--color-mile)"}} 
       onClick={() => {navigate(Routeconstant.RMSREPORT)}}>{item.InquiryNo}</td> */}
                <td>{item.Transportmode}</td>
                <td>{item.OriginPort}</td>
                <td>{item.destinationport}</td>
                <td>{item.Incoterm}</td>
                <td>{item.Carotype}</td>
                <td>{dayjs(item.Cargoreadydate).format("DD-MM-YYYY")}</td>
                <td>{item.StatusDescription}</td>
                <td>
                  <IconButton>
                    <DownloadForOfflineOutlinedIcon />
                  </IconButton>
                </td>
                <td>
                  <IconButton onClick={handleMenuOpen}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleMenuClose}>
                      <Edit sx={{ marginRight: "8px" }} />
                      Edit
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                      <ChatBubbleOutline sx={{ marginRight: "8px" }} />
                      Chat To Negotiate
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                      <NotificationsNone sx={{ marginRight: "8px" }} />
                      Turn On Notifications
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                      <Restore sx={{ marginRight: "8px" }} />
                      Convert To Shipment
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                      <Share sx={{ marginRight: "8px" }} />
                      Share
                    </MenuItem>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="new-slot">
        {/* <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        > */}
        <Fab color="primary" aria-label="add" style={{ marginLeft: "35px" }}>
          <AddIcon
            sx={{ fontSize: "2.5rem" }}
            onClick={() => {
              navigate(`${Routeconstant.CREATERMS}`);
            }}
            // className={
            //   !open
            //     ? "img-arrow-user "
            //      : "img-arrow-user  active-img-arrow-user"
            // }
            // className={open ? "icon-transform" : "icon-transform-none"}
          />
        </Fab>
        {/* </Button> */}

        {/* <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            > */}
        {/* <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={handleClose}
                      className="mb-3 d-flex justify-content-between"
                    >
                      <div className="menu-data">Use saved templates</div>
                      <Fab color="primary" aria-label="add" size="small">
                        <BookmarkBorderIcon />
                      </Fab>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <div className="menu-data me-4">Create new templates</div>
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        onClick={() => {
                          navigate(`${Routeconstant.CREATERMS}`);
                        }}
                      >
                        <AddIcon />
                      </Fab>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper> */}
        {/* </Grow>
          )}
        </Popper> */}
      </div>
    </>
  );
};

export default Rms;
