import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";
import Login from "../screens/auth/login/Login";
import { Routeconstant } from "./utils/RouteConstant";
import { basename, LOCAL_STORAGE_KEY } from "../Config";
import Shipment from "../screens/shipment";
import Order from "../screens/order";
import ShipmentDetails from "../screens/shipment/components/ShipmentDetails";
import OrderDetails from "../screens/order/components/Details";
import Routing from "../screens/shipment/components/Routing";
import CargoDetails from "../screens/shipment/components/CargoDetails";
import Documentation from "../screens/shipment/components/Documentation";
import OrderCargoDetails from "../screens/order/components/Cargodetails";
import OrderRouting from "../screens/order/components/Routing.js";
import OrderDocumentation from "../screens/order/components/Documentation.js";
import ShipmentAnalytics from "../screens/shipment/components/ShipmentAnalytics";
import OrderAnalytics from "../screens/order/components/OrderAnalytics";
import Enquiry from "../screens/Enquiry";
import Statement from "../screens/statement";
import Users from "../screens/users";
import NoRoute from "../common/NoRoute";
import Nointernet from "../common/Nointernet";
import PrivateRoute from "./component/PrivateRoute";
import PublicRoute from "./component/PublicRoute";

import { AuthContext } from "../store/context/AuthProvider";
import ResetPassword from "../screens/auth/reset/Resetpassword";
import ForgetPassword from "../screens/auth/forget/ForgetPassword.js";
import Dashboard from "../screens/dashboard/Dashboard";
import { localData } from "../services/auth-header";
import ShipmentDetailsPublic from "../screens/publicscreens/ShipmentDetailsPublic";
import Rms from "../screens/rms/Rms";
import CreateRms from "../screens/rms/CreateRms";
import CreateRmsList from "../screens/rms/components/CreateRmsList";
import CargoRates from "../screens/rms/components/CargoRates";
import DocumentRms from "../screens/rms/components/DocumentRms";
import RmsReport from "../screens/rms/components/RmsReport";

const Navigation = () => {
  const context = useContext(AuthContext);
  const [online, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    window.addEventListener("offline", () => setOnline(false));
    window.addEventListener("online", () => setOnline(true));
  }, []);
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  console.log("context.userData:", storedUserData);
  console.log("Routeconstant.USER_LOGS:", Routeconstant.USER_LOGS);
  return (
    <BrowserRouter basename={basename}>
      {online ? (
        <Routes>
          <Route
            path={Routeconstant.LOGIN}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={Routeconstant.SHIPMENTDETAILS}
            element={
              <PublicRoute>
                <ShipmentDetailsPublic />
              </PublicRoute>
            }
          />

          <Route
            path={Routeconstant.RESETPASSWORD}
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path={Routeconstant.FORGETPASSWORD}
            element={
              <PublicRoute>
                <ForgetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          >
            <Route path={Routeconstant.LANDING_PAGES} element={<Dashboard />} />

            {/* shipment listing */}
            <Route path={Routeconstant.SHIPMENT_LISTING} element={<Shipment />}>
              <Route
                path={Routeconstant.DETAILS}
                element={<ShipmentDetails />}
              />
              <Route path={Routeconstant.ROUTING} element={<Routing />} />
              <Route
                path={Routeconstant.CARGO_DETAILS}
                element={<CargoDetails />}
              />
              <Route
                path={Routeconstant.DOCUMENTATION}
                element={<Documentation />}
              />
            </Route>

            {/* Shipment Analytics */}
            <Route
              path={Routeconstant.SHIPMENT_ANALYTICS}
              element={<ShipmentAnalytics />}
            ></Route>

            {/* Order listing */}
            {/* <Route path={Routeconstant.ORDER_LISTING} element={<Order />}>
              <Route
                path={Routeconstant.ORDER_DETAILS}
                element={<OrderDetails />}
              />
              <Route
                path={Routeconstant.ORDER_ROUTING}
                element={<OrderRouting />}
              />
              <Route
                path={Routeconstant.ORDER_CARGO_DETAILS}
                element={<OrderCargoDetails />}
              />
              <Route
                path={Routeconstant.ORDER_DOCUMENTATION}
                element={<OrderDocumentation />}
              />
            </Route> */}

            {/* Order Analyics */}
            {/* <Route
              path={Routeconstant.ORDER_ANALYTICS}
              element={
                <PrivateRoute>
                  <OrderAnalytics />
                </PrivateRoute>
              }
            ></Route> */}

            {/* Enquiry */}
            <Route
              path={Routeconstant.ENQUIRY}
              element={
                <PrivateRoute>
                  <Enquiry />
                </PrivateRoute>
              }
            ></Route>
            {/* RMS */}
            {/* <Route
              path={Routeconstant.RMS}
              element={
                <PrivateRoute>
                  <Rms />
                </PrivateRoute>
              }
            /> */}
            {/* CREATE-RMS */}
            {/* <Route
              path={Routeconstant.CREATERMS}
              element={
                <PrivateRoute>
                  <CreateRms />
                </PrivateRoute>
              }
            /> */}
            {/* CREATELIST-RMS */}
            {/* <Route
              path={Routeconstant.CREATERMSLIST}
              element={
                <PrivateRoute>
                  <CreateRmsList />
                </PrivateRoute>
              }
            /> */}
            {/* CARGORATES-RMS */}
            {/* <Route
              path={Routeconstant.CARGORATES}
              element={
                <PrivateRoute>
                  <CargoRates />
                </PrivateRoute>
              }
            /> */}
            {/* Document-RMS */}
            {/* <Route
              path={Routeconstant.DOCUMENTRMS}
              element={
                <PrivateRoute>
                  <DocumentRms />
                </PrivateRoute>
              }
            /> */}
            {/* Report-RMS */}
            {/* <Route
              path={Routeconstant.RMSREPORT}
              element={
                <PrivateRoute>
                  <RmsReport />
                </PrivateRoute>
              }
            /> */}
            {/* Statement of Account */}
            <Route
              path={Routeconstant.STATEMENT}
              element={<Statement />}
            ></Route>

            {/* user logs */}
            {storedUserData?.Role?.includes("Admin") && (
              <Route path={Routeconstant.USER_LOGS} element={<Users />}></Route>
            )}
          </Route>

          {/* No route */}
          <Route path="*" element={<NoRoute />} />
        </Routes>
      ) : (
        <Nointernet />
      )}
    </BrowserRouter>
  );
};

export default Navigation;
