import "./App.css";
import { Toaster } from "react-hot-toast";
import Navigation from "./routing/Navigation";
import { AuthProvider } from "./store/context/AuthProvider";
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <AuthProvider>
        <Toaster
          toastOptions={{
            position: "top-center",
          }}
        />
        <Navigation />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
