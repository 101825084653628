import { Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { basename } from "../../Config";
import React, { useState } from "react";
// import "./dashboard.css";
import Carousel from "react-material-ui-carousel";
import { useEffect } from "react";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";

export default function Finances() {
  const [outstandingCollection, setOutstandingCollection] = useState([]);
  const [dueAmountCollection, setDueAmountCollection] = useState([]);
  const [isLoading, setisLoading] = useState({
    outstanding: false,
  });
  useEffect(() => {
    getOustandingAmout();
  }, []);

  const getOustandingAmout = () => {
    setisLoading({ ...isLoading, outstanding: true });
    const body = {
      ShipmentId: "",
      ETAFrom: "",
      ETATo: "",
      ETDFrom: "",
      ETDTo: "",
      ATDFrom: "",
      ATDTo: "",
      ATAFrom: "",
      ATATo: "",
      ShowDeveloperResponse: true,
      DateFrom: "",
      DateTo: "",
      Status: "",
      CurrentPage: 0,
      PageSize: 0,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post(API_PATH.GET_OUTSTANDINGAMOUNT, body)
      .then((res) => {
        setOutstandingCollection(res?.data[0]?.OutstandingCollection ?? []);
        setDueAmountCollection(res?.data[0]?.DueAmountCollection ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading({ ...isLoading, outstanding: false });
      });
  };

  return (
    <>
      <div className="finances-rms-container">
        <div className="finances-container">
          <div className="finance-heading">Finances</div>
          <div className="outstanding-due-container">
            <div className="outstanding-container">
              <div className="heading-outstanding">Outstanding</div>
              <div className="data-outstanding">
                <div className="d-flex justify-content-between px-2 align-items-center table-head">
                  <div>Currency</div>
                  <div>Amount</div>
                </div>
                {outstandingCollection.length ? (
                  <>
                    {outstandingCollection.map((item, index) => {
                      return (
                        <div
                          className="d-flex justify-content-between  p-2 table-data"
                          key={index}
                        >
                          <div>{item.Currency ?? ""}</div>
                          <div>{item.Amount ?? ""}</div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "7rem",
                      fontSize: "var(--fs-small)",
                      color: "var(--color-miscellaneous-2)",
                    }}
                  >
                    No data found!
                  </div>
                )}
              </div>
            </div>
            <div className="due-amount-container">
              <div className="heading-due-amount">Due Amount</div>
              <div className="data-due-amount">
                <div className="d-flex justify-content-between px-2 align-items-center table-head">
                  <div>Currency</div>
                  <div>Amount</div>
                </div>
                {dueAmountCollection.length ? (
                  <>
                    {dueAmountCollection.map((item, index) => {
                      return (
                        <div
                          className="d-flex justify-content-between p-2 table-data"
                          key={index}
                        >
                          <div>{item?.Currency ?? ""}</div>
                          <div>{item?.Amount ?? ""}</div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "7rem",
                      fontSize: "var(--fs-small)",
                      color: "var(--color-miscellaneous-2)",
                    }}
                  >
                    No data found!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="rms-container">
          <div className="rms-heading">Complete your Pending RMS</div>
          <Carousel animation="slide" className="rms-carousel">
            {[1, 2, 3].map(() => {
              return (
                <Paper
                  elevation={4}
                  className="common-padding"
                  // style={{
                  //   height: "10rem",
                  // }}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={9} xs={9}>
                      <Typography
                        variant="body2"
                        color={"primary"}
                        style={{ fontSize: "var(--fs-mid)" }}
                      >
                        1234567890987654
                      </Typography>
                    </Grid>
                    <Grid item lg={2} xs={2}>
                      <IconButton>
                        <img
                          alt="ship-icon"
                          style={{ height: "23px", width: "21px" }}
                          src={urlResolver("assets/ship.svg")}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item lg={4} xs={4}>
                      <Stack>
                        <Typography
                          variant="caption"
                          style={{ fontSize: "var(--fs-small)" }}
                        >
                          Origin
                        </Typography>
                        <Typography
                          variant="caption"
                          color="#5B5675"
                          style={{ fontSize: "var(--fs-mid)" }}
                        >
                          Sheikou Port
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item lg={4} xs={4}>
                      <Stack>
                        <Typography
                          variant="caption"
                          style={{ fontSize: "var(--fs-small)" }}
                        >
                          Destination
                        </Typography>
                        <Typography
                          variant="caption"
                          color="#5B5675"
                          style={{ fontSize: "var(--fs-mid)" }}
                        >
                          Sheikou Port
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item lg={4} xs={4}>
                      <Stack>
                        <Typography
                          variant="caption"
                          style={{ fontSize: "var(--fs-small)" }}
                        >
                          {" "}
                          SOB Date
                        </Typography>
                        <Typography
                          variant="caption"
                          color="#5B5675"
                          style={{ fontSize: "var(--fs-mid)" }}
                        >
                          12-12-2022
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Carousel>
          <div className="rms-text">Comming Soon </div>
        </div> */}
      </div>
    </>
  );
}
