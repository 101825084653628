import { createTheme } from "@mui/material/styles";
export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#236c9c",
      // light: '#757ce8',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    secondary: {
      main: "#5b5675",
      // light: '#757ce8',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    third : {
      main : "#fff"
    }
  },
});
