import axios from "axios";
import React, { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { basename, baseUrl, LOCAL_STORAGE_KEY } from "../../../Config";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { AuthContext } from "../../../store/context/AuthProvider";
import { Buffer } from "buffer";
import jwt_decode from "jwt-decode";
import "./login.css";
import { ConeSeries } from "@amcharts/amcharts4/charts";
import { toast } from "react-hot-toast";
import { API_PATH } from "../../../constant/apiPath";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { LoginWithPassword } from "../../../helpers/AuthValidator";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect } from "react";
import { FaSyncAlt } from "react-icons/fa";
import urlResolver from "../../../utils/urlResolver";
import CloseIcon from "@mui/icons-material/Close";

const Login = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [fieldError, setFieldError] = useState({});
  const [isError, setIsError] = useState(false);
  const [id, setId] = useState(null);
  const [field, setField] = useState({
    Email: "",
    Password: "",
  });
  const [trackYourOrder, setTrackYourOrder] = useState(false);
  const [showcaptcha, setShowcaptcha] = useState(true);
  const [showcaptchaTrack, setShowcaptchaTrack] = useState(true);
  const [captchaCode, setCaptchaCode] = useState("");
  const [captchaCodeTrack, setCaptchaCodeTrack] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [captchaTrack, setCaptchaTrack] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [captchaErrorTrack, setCaptchaErrorTrack] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const [errorCountTrack, setErrorCountTrack] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [backgroundImageTrack, setBackgroundImageTrack] = useState("");
  const [hiddenAddToHandle, setHiddenAddToHandle] = useState(true);
  const handlePaste = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    generateCaptcha();
    generateBackgroundImage();
    generateCaptchatrack();
    generateBackgroundImageTrack();
  }, []);
  // const clearCaptchaInput = () => {
  //   if (captchaRef.current) {
  //     captchaRef.current.value = "";
  //   }
  //   console.log("captchaRef",captchaRef.current);
  // };
  const generateCaptcha = () => {
    // // Generate a random CAPTCHA code
    // const code = Math.random().toString(36).slice(2, 8).toUpperCase();
    // setCaptchaCode(code);
    // // Reset error count
    // setErrorCount(0);
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    // Generate a random CAPTCHA code
    let code = "";
    // Generate characters of both cases
    for (let i = 0; i < 6; i++) {
      const randomChar =
        characters[Math.floor(Math.random() * characters.length)];
      code += randomChar;
    }
    setCaptchaCode(code);
    // Reset error count
    setErrorCount(0);
  };
  const generateCaptchatrack = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    // Generate a random CAPTCHA code
    let code = "";
    // Generate characters of both cases
    for (let i = 0; i < 6; i++) {
      const randomChar =
        characters[Math.floor(Math.random() * characters.length)];
      code += randomChar;
    }
    setCaptchaCodeTrack(code);
    // Reset error count
    setErrorCountTrack(0);
  };
  const generateBackgroundImage = () => {
    const patterns = [
      "pattern-dot",
      "pattern-dash",
      "pattern-cross-dots",
      "pattern-cross",
    ];
    const randomIndex = Math.floor(Math.random() * patterns.length);
    const patternClass = patterns[randomIndex];
    setBackgroundImage(patternClass);
  };
  const generateBackgroundImageTrack = () => {
    const patterns = [
      "pattern-dot",
      "pattern-dash",
      "pattern-cross-dots",
      "pattern-cross",
    ];
    const randomIndex = Math.floor(Math.random() * patterns.length);
    const patternClass = patterns[randomIndex];
    setBackgroundImageTrack(patternClass);
  };
  const validateCaptcha = (e) => {
    setCaptcha(e.target.value);
    if (e.target.value.trim() === "") {
      setCaptchaError("Enter the CAPTCHA code!");
    } else if (e.target.value.trim() !== captchaCode) {
      setCaptchaError("Enter the valid CAPTCHA code!");
    } else {
      setCaptchaError("");
    }
  };
  const validateCaptchatrack = (e) => {
    setCaptchaTrack(e.target.value);
    if (e.target.value.trim() === "") {
      setCaptchaErrorTrack("Enter the CAPTCHA code!");
    } else if (e.target.value.trim() !== captchaCodeTrack) {
      setCaptchaErrorTrack("Enter the valid CAPTCHA code!");
    } else {
      setCaptchaErrorTrack("");
    }
  };
  const refreshCaptcha = () => {
    generateCaptcha();
    generateBackgroundImage();
  };
  const refreshCaptchaTrack = () => {
    generateCaptchatrack();
    generateBackgroundImageTrack();
  };
  const handleInputChange =
    (input) =>
    ({ target: { value } }) => {
      setIsError(false);
      setField({
        ...field,
        [input]: value,
      });

      const formErrors = { ...fieldError };

      switch (input) {
        case "Password":
          formErrors.Password =
            value.length >= 0 && value.replaceAll(" ", "").length < 5
              ? "Minimum 5 character required"
              : "";
          break;

        case "Email":
          formErrors.Email =
            value.match(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) == null
              ? "Please add valid emailId"
              : "";
          break;

        default:
          break;
      }

      Object.values(formErrors).forEach(function (error) {
        if (error.length > 0) {
          setIsError(true);
        }
      });

      setFieldError({
        ...formErrors,
      });
    };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleKeyPress = (event) => {
    if (event && event.key === "Enter") {
      event.preventDefault();

      // Refresh CAPTCHA code
      // refreshCaptcha();

      // Trigger login process
      onUserLogin();
    }
  };
  const handleKeyPressship = (event) => {
    if (event && event.key === "Enter") {
      event.preventDefault();

      // Refresh CAPTCHA code
      // refreshCaptcha();

      // Trigger login process
      navigate(`${"/shipment/details/"}${id}`);
    }
  };
  const onUserLogin = (event) => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);
    const newErrorCount = errorCount + 1;
    setErrorCount(newErrorCount);

    if (showcaptcha) {
      if (captcha.trim() === "") {
        setCaptchaError("Enter the CAPTCHA code!");
        setLoading(false);
        return;
      }
      // Validate CAPTCHA code
      if (captcha.trim() !== captchaCode) {
        setCaptchaError("Enter the valid CAPTCHA code!");
        setLoading(false);
        return;
      }
    }
    refreshCaptcha();
    const emptyFields = Object.keys(field).filter(
      (key) => field[key].trim() === ""
    );
    if (emptyFields.length > 0) {
      const newFieldError = { ...fieldError };
      emptyFields.forEach((emptyField) => {
        newFieldError[emptyField] = "This field is required";
      });
      setFieldError(newFieldError);
      setLoading(false);
      return;
    }
    // setCaptcha("");
    // console.log("captcha",captcha);
    const token = Buffer.from(
      `${field.Email}:${field.Password}`,
      "utf8"
    ).toString("base64");
    const config = {
      method: "post",
      url: `${baseUrl}${API_PATH.LOGIN}`,
      headers: { Authorization: `Basic ${token}` },
    };
    axios(config)
      .then((response) => {
        setLoading(false);
        if (response.data.IsSuccess) {
          context.setToken(response.data.ResponseData);
          context.setIslogin(true);
          localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(response.data.ResponseData)
          );
          const decoded = jwt_decode(response.data.ResponseData.AccessToken);
          let obj = {
            Role: "",
            Name: "",
            Email: "",
          };
          if (Object.keys(decoded).length) {
            Object.keys(decoded).map((item) => {
              if (item.includes("role")) {
                obj.Role = decoded[item];
              } else if (item.includes("name")) {
                obj.Name = decoded[item];
              } else if (item.includes("emailaddress")) {
                obj.Email = decoded[item];
              }
            });
          }
          context.setUserData(obj);
          setErrorCount(0);
          setCaptcha("");
          // clearCaptchaInput();
          navigate(Routeconstant.LANDING_PAGES);
        } else {
          toast.error(response.data.Message.Message);
          const newToastErrorCount = errorCount + 1;
          // if (newToastErrorCount >= 4) {
          //   setShowcaptcha(true);
          // }
          setErrorCount(newToastErrorCount);
          // clearCaptchaInput();
          setCaptcha("");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        setLoading(false);
        console.log(error);
        setCaptcha("");
      });
  };
  const Captcharefresh = (event) => {
    event.preventDefault();
  };
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();

      setDeferredPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);
  const handleAddToHomeScreen = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
  };
  const AddtoHomeScreenDialog = () => {
    if (window.innerWidth < 768)
      return (
        <>
          {hiddenAddToHandle && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgb(124 124 124 / 53%)",
                width: "100%",
                padding: "10px",
                top: "0px",
                zIndex: "10",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={handleAddToHomeScreen}
                variant="contained"
                color="primary"
                size="small"
              >
                Add to Home Screen
              </Button>
              <IconButton
                size="small"
                color="var(--color-miscellaneous-7)"
                onClick={() => {
                  setHiddenAddToHandle(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </>
      );
  };

  return (
    <Grid
      container
      direction={{
        lg: "row",
        md: "row",
        sm: "column-reverse",
        xs: "column-reverse",
      }}
      sx={{
        flexWrap: "inherit",
      }}
    >
      <AddtoHomeScreenDialog />
      {/* SIGN IN */}
      <Grid
        item
        lg={5}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "68%",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{
              marginBottom: "2%",
            }}
          >
            Sign In
          </Typography>
          {/* <Typography variant="overline">Lets Build Something great</Typography> */}
          <form onSubmit={onUserLogin}>
            <FormGroup>
              <FormLabel
                id="Email"
                sx={{
                  fontSize: "80%",
                  margin: "0",
                }}
              >
                Email
              </FormLabel>
              <TextField
                fullWidth
                size="small"
                id="Email"
                name="Email"
                onChange={handleInputChange("Email")}
                sx={{
                  marginBottom: "3%",
                }}
                error={fieldError["Email"]}
                helperText={fieldError["Email"]}
              />
              <FormLabel
                id="password"
                sx={{
                  fontSize: "80%",
                  margin: "0",
                }}
              >
                Password
              </FormLabel>
              <TextField
                sx={{
                  marginBottom: "4%",
                }}
                fullWidth
                size="small"
                type={showPassword ? "text" : "password"}
                id="Password"
                name="Password"
                onChange={handleInputChange("Password")}
                error={fieldError["Password"]}
                helperText={fieldError["Password"]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {showcaptcha && (
                <>
                  <FormLabel
                    id="captcha"
                    sx={{
                      fontSize: "80%",
                      margin: "0",
                    }}
                  >
                    CAPTCHA
                  </FormLabel>
                  <TextField
                    fullWidth
                    size="small"
                    id="captcha"
                    name="captcha"
                    onChange={validateCaptcha}
                    value={captcha}
                    error={!!captchaError}
                    onPaste={handlePaste}
                    helperText={captchaError}
                    onKeyDown={handleKeyPress}
                  />
                  <div className="captcha-container">
                    <div className={`captcha-code ${backgroundImage}`}>
                      {captchaCode.split("").map((char, index) => (
                        <span key={index}>{char}</span>
                      ))}
                    </div>
                    <button className="refresh-button" onClick={refreshCaptcha}>
                      <FaSyncAlt />
                    </button>
                  </div>
                </>
              )}

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={Object.keys(fieldError).length === 0 || isError}
                // onClick={() => {
                //   onUserLogin();
                // }}
                // onClick={onUserLogin}
              >
                {loading ? (
                  <CircularProgress
                    size="2rem"
                    style={{ color: "var(--color-miscellaneous-4)" }}
                  />
                ) : (
                  "Login"
                )}
              </Button>
              <Typography
                variant="caption"
                color="primary"
                align="right"
                sx={{
                  cursor: "pointer",
                }}
              >
                <span
                  onClick={() => {
                    navigate(Routeconstant.FORGETPASSWORD);
                  }}
                >
                  {" "}
                  Forgot password?
                </span>
              </Typography>
              {/* <Link href="#" underline="none" align="right">
              {"Forgot password?"}
            </Link> */}
            </FormGroup>
          </form>
          <Divider>OR</Divider>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              marginTop: "3%",
              marginBottom: "3%",
            }}
            onClick={() => setTrackYourOrder(true)}
          >
            Track your Shipment
          </Button>
        </Box>
      </Grid>
      {/* COMPANY INFO */}
      {!trackYourOrder ? (
        <Grid
          item
          lg={7}
          md={12}
          sm={12}
          xs={12}
          className="second-section"
          key={1}
          style={{ position: "relative" }}
        >
          {/* --specific changes for 1Up cargo  */}
          {/* <Grid container className="login-background-opacity "></Grid> */}
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "1rem",
                position: "absolute",
                top: "40%",
              }}
            >
              <img
                src={urlResolver(`assets/headerLogo.png`)}
                style={{ width: "30vw" }}
              />
            </div>
          </Grid>
          {/* ----------------  */}
        </Grid>
      ) : (
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "68%",
            }}
          >
            <Typography
              variant="h5"
              align="center"
              sx={{
                marginBottom: "",
              }}
            >
              Track Shipment
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  fontSize: "0.6rem",
                  color: "grey",
                }}
              >
                Know exactly where your shipment is
              </Typography>
            </div>

            <form onSubmit={Captcharefresh}>
              <FormGroup>
                <FormLabel
                  id="ShipmentNumber"
                  sx={{
                    fontSize: "80%",
                    margin: "0",
                  }}
                >
                  Shipment /House Bill Number /Mbl Number
                </FormLabel>
                <TextField
                  fullWidth
                  value={id}
                  size="small"
                  id="ShipmentNumber"
                  name="ShipmentNumber"
                  onChange={(e) => setId(e.target.value)}
                  sx={{
                    marginBottom: "3%",
                  }}
                  // error={fieldError["Email"]}
                  // helperText={fieldError["Email"]}
                />
                {showcaptchaTrack && (
                  <>
                    <FormLabel
                      id="captchaTrack"
                      sx={{
                        fontSize: "80%",
                        margin: "0",
                      }}
                    >
                      CAPTCHA
                    </FormLabel>
                    <TextField
                      fullWidth
                      size="small"
                      id="captchaTrack"
                      name="captchaTrack"
                      onChange={validateCaptchatrack}
                      value={captchaTrack}
                      error={!!captchaErrorTrack}
                      onPaste={handlePaste}
                      helperText={captchaErrorTrack}
                      onKeyDown={handleKeyPressship}
                    />
                    <div className="captcha-container">
                      <div className={`captcha-code ${backgroundImageTrack}`}>
                        {captchaCodeTrack.split("").map((char, index) => (
                          <span key={index}>{char}</span>
                        ))}
                      </div>
                      <button
                        className="refresh-button"
                        onClick={refreshCaptchaTrack}
                      >
                        <FaSyncAlt />
                      </button>
                    </div>
                  </>
                )}
                {console.log(
                  "dfgdfgdfg",
                  id === null && captchaTrack !== "",
                  id === null,
                  captchaTrack !== ""
                )}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!id || !captchaTrack || captchaErrorTrack}
                  onClick={() => {
                    navigate(`${"/shipment/details/"}${id}`);
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size="2rem"
                      style={{ color: "var(--color-miscellaneous-4)" }}
                    />
                  ) : (
                    "Track"
                  )}
                </Button>
              </FormGroup>
            </form>
          </Box>
        </Grid>
      )}
    </Grid>
    // <>
    //   <div className="login ">
    //     <div className="container-fluid height-100">
    //       <div className="row height-100">
    //         <div className="col-6 first-section d-flex justify-content-center align-items-center">
    //           <div className="section-login">
    //             <div>
    //               <p className="login-head">Sign In</p>
    //               {/* <p className="login-mid-head">lets Build something great</p> */}
    //             </div>
    //             {
    //               <Formik
    //                 initialValues={LoginWithPassword.initials}
    //                 validationSchema={LoginWithPassword.validation}
    //                 onSubmit={(values, actions) => {
    //                   onUserLogin(values, actions);
    //                 }}
    //               >
    //                 {(props) => (
    //                   <form onSubmit={props.handleSubmit}>
    //                     <div>
    //                       <div>
    //                         <label className="form-label form-style">
    //                           Email address
    //                         </label>
    //                         <input
    //                           type="email"
    //                           className="form-control"
    //                           placeholder="name@example.com"
    //                           value={props.values.email}
    //                           onChange={(event) => {
    //                             props.setFieldValue(
    //                               "email",
    //                               event.target.value
    //                             );
    //                           }}
    //                         />
    //                         {props.errors.email && props.touched.email && (
    //                           <span className="span-error">
    //                             {props.errors.email}
    //                           </span>
    //                         )}
    //                       </div>
    //                       <div>
    //                         <label className=" col-form-label form-style ">
    //                           Password
    //                         </label>

    //                         <input
    //                           type="password"
    //                           className="form-control form-label"
    //                           value={props.values.password}
    //                           onChange={(event) => {
    //                             props.setFieldValue(
    //                               "password",
    //                               event.target.value
    //                             );
    //                           }}
    //                         />
    //                         {props.errors.password &&
    //                           props.touched.password && (
    //                             <span className="span-error">
    //                               {props.errors.password}
    //                             </span>
    //                           )}
    //                       </div>

    //                       <button type="submit" className="login-btn">
    //                         {loading ? (
    //                           <CircularProgress
    //                             size="2rem"
    //                             style={{ color: "var(--color-miscellaneous-4)" }}
    //                           />
    //                         ) : (
    //                           "Login"
    //                         )}
    //                       </button>
    //                     </div>
    //                     <div
    //                       className=" forget-password-div margin-div"
    //                       onClick={() => {
    //                         navigate(Routeconstant.FORGETPASSWORD);
    //                       }}
    //                     >
    //                       <a href="" className="forget-link">
    //                         forget password?
    //                       </a>
    //                     </div>
    //                   </form>
    //                 )}
    //               </Formik>
    //             }
    //           </div>
    //         </div>
    //         <div className="col-6 second-section">
    //           <div className="login-background-opacity ">
    //             <div className="text-box">
    //               <p className="insight-font">
    //                 Welcome to{" "}
    //                 <span className="font-weight-bold">SFL Insights</span>
    //               </p>
    //               {/* <div className="line-second-section"></div> */}
    //               {/* <p>
    //                 Låst bev glokal. Pron misade spese. Ultrass nyren i autobel.
    //                 Sosa jyde. Astrobörade. Dire tristik. Reang faraligt.
    //                 Sefulig divongar. Jynar. Öktigt växtblindhet pres.{" "}
    //               </p>
    //               <button className="read-btn">Read More</button> */}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
};

export default Login;
