import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
const UserFilterModal = ({
  setUserFilter,
  userFilter,
  setFilterData,
  filterData,
  getAllUser,
  setPageNo,
  pageNo,
  filterObj,
}) => {
  const handleClose = () => setUserFilter(false);
  const filterOptions = ["Admin", "Buyer", "Supplier", "Agent"];
  const clearObj = {
    UserName: "",
    OrgFullName: "",
    Orgcode: "",
    RegisterdDateFrom: null,
    RegisterdDateTo: null,
    LastLoginDateFrom: null,
    LastLoginDateTo: null,
    AccessGranted: "",
    Role: "",
    ActiveStatus: "",
  };
  return (
    <Dialog
      open={userFilter}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent>
        <Card style={{ border: "none", boxShadow: "none" }}>
          <CardHeader
            action={
              <Button
                className="font-change"
                onClick={() => {
                  setFilterData({ ...clearObj });
                  filterObj.current = { ...clearObj };
                }}
              >
                Reset all
              </Button>
            }
            subheader={
              <Button
                className="font-change"
                color={"primary"}
                onClick={() => {
                  setUserFilter(false);
                  filterObj.current = { ...clearObj };
                  setFilterData(clearObj);
                  getAllUser(pageNo, filterData, "");
                }}
              >
                <KeyboardBackspaceIcon /> Back
              </Button>
            }
          />
          <Divider sx={{ backgroundColor: "var(--color-miscellaneous-1)" }} />
          <CardContent>
            <Grid container spacing={2}>
              {/* -------User Name------ */}
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography className="font-change">User Name</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filterData.UserName}
                        placeholder="Please enter text"
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z _-]+$/;
                          if (
                            e.target.value.match(allowedCharacters) ||
                            e.target.value.length === 0
                          ) {
                            setFilterData((prev) => {
                              return { ...prev, UserName: e.target.value };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid> */}
              {/* ------Organization------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="font-change">
                      Organization
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={12}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filterData.OrgFullName}
                        placeholder="Please enter Organisation"
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z\s_-]+$/;
                          if (
                            e.target.value.match(allowedCharacters) ||
                            e.target.value.length === 0
                          ) {
                            setFilterData((prev) => {
                              return {
                                ...prev,
                                OrgFullName: e.target.value,
                              };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------Organization code------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="font-change">
                      Organization code
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={12}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filterData.Orgcode}
                        placeholder="Please enter Organisation code"
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z\s_-]+$/;
                          if (
                            e.target.value.match(allowedCharacters) ||
                            e.target.value.length === 0
                          ) {
                            setFilterData((prev) => {
                              return {
                                ...prev,
                                Orgcode: e.target.value,
                              };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* -------Registerd Date------ */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Typography className="font-change">
                      Registerd Date
                    </Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      className="col-6  justify-content-between align-items-center"
                    >
                      <DatePicker
                        className="w-100 datepicker"
                        inputFormat="DD/MMM/YYYY"
                        label="Enter Date"
                        value={filterData.RegisterdDateFrom}
                        maxDate={filterData?.RegisterdDateTo}
                        disableFuture
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            RegisterdDateFrom: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={1} md={12} sm={12} xs={12}>
                    <Typography align="center" className="font-change">
                      to
                    </Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      className="col-6 justify-content-between align-items-center"
                    >
                      <DatePicker
                        inputFormat="DD/MMM/YYYY"
                        className="w-100 datepicker"
                        label="Enter Date"
                        size="small"
                        value={filterData.RegisterdDateTo}
                        disableFuture
                        minDate={filterData?.RegisterdDateFrom}
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            RegisterdDateTo: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              {/* -------Login date------ */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Typography className="font-change">Login date</Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      className="col-6  justify-content-between align-items-center"
                    >
                      <DatePicker
                        className="w-100 datepicker"
                        inputFormat="DD/MMM/YYYY"
                        label="Enter Date"
                        value={filterData?.LastLoginDateFrom}
                        maxDate={filterData?.LastLoginDateTo}
                        disableFuture
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            LastLoginDateFrom: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={1} md={12} sm={12} xs={12}>
                    <Typography align="center" className="font-change">
                      to
                    </Typography>
                  </Grid>
                  <Grid item lg={3.5} md={12} sm={12} xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      className="col-6 justify-content-between align-items-center"
                    >
                      <DatePicker
                        inputFormat="DD/MMM/YYYY"
                        className="w-100 datepicker"
                        label="Enter Date"
                        size="small"
                        value={filterData?.LastLoginDateTo}
                        disableFuture
                        minDate={filterData?.LastLoginDateFrom}
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            LastLoginDateTo: e,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              {/* -------Access Granted------ */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="font-change">
                      Access Granted
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={12}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filterData.AccessGranted}
                        placeholder="Please enter text"
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z _-]+$/;
                          if (
                            e.target.value.match(allowedCharacters) ||
                            e.target.value.length === 0
                          ) {
                            setFilterData((prev) => {
                              return { ...prev, AccessGranted: e.target.value };
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* -------Role------ */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="font-change">Role</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={12}>
                    <FormControl size="small" fullWidth>
                      <Select
                        // THIS ATTRIBUTE ADD THE CROSS AT THE RIGHT CORNER
                        endAdornment={
                          filterData.Role && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                setFilterData({
                                  ...filterData,
                                  Role: "",
                                });
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          )
                        }
                        value={filterData.Role}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            Role: e.target.value,
                          });
                        }}
                      >
                        {filterOptions.map((item, index) => {
                          return (
                            <MenuItem
                              className="font-change"
                              key={index}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* -------Active status------ */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="font-change">
                      Active status
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={12}>
                    <FormControl>
                      <RadioGroup
                        onClick={(e) => {
                          if (filterData?.ActiveStatus == e.target.value) {
                            setFilterData({
                              ...filterData,
                              ActiveStatus: "",
                            });
                          } else {
                            setFilterData({
                              ...filterData,
                              ActiveStatus: e.target.value,
                            });
                          }
                        }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Active"
                          control={
                            <Radio
                              checked={filterData?.ActiveStatus == "Active"}
                            />
                          }
                          label="Active"
                        />
                        <FormControlLabel
                          value="InActive"
                          control={
                            <Radio
                              checked={filterData?.ActiveStatus == "InActive"}
                            />
                          }
                          label="InActive"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className="d-flex justify-content-end">
            <Button
              className="font-change"
              onClick={() => {
                filterObj.current = { ...filterData };
                getAllUser(0, filterData, "");
                setPageNo(0);
                setUserFilter(false);
              }}
            >
              Search
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default UserFilterModal;
