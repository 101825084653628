import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { basename, baseUrl, LOCAL_STORAGE_KEY } from "../../../Config";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { AuthContext } from "../../../store/context/AuthProvider";
import { Buffer } from "buffer";
import "../login/login.css";
import { ConeSeries } from "@amcharts/amcharts4/charts";
import { toast, Toaster } from "react-hot-toast";
import { API_PATH } from "../../../constant/apiPath";
import validator from "validator";
import { FaSyncAlt } from "react-icons/fa";
import RandomImageCreator from "random-image-creator";
import { generate } from "patternomaly";
import "pattern.css/dist/pattern.min.css";
import urlResolver from "../../../utils/urlResolver";
import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [captchaCode, setCaptchaCode] = useState("");
  const [isEmailValidated, setIsEmailValidated] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [timer, setTimer] = useState(30);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    generateCaptcha();
    generateBackgroundImage();
  }, []);

  useEffect(() => {
    // generateCaptcha();
    if (isLinkSent && !isTimerRunning) {
      setTimer(60);
      setIsLinkSent(false);
    }
  }, [isLinkSent, isTimerRunning]);

  const generateCaptcha = () => {
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let code = "";
    for (let i = 0; i < 6; i++) {
      code += chars[Math.floor(Math.random() * chars.length)];
    }
    setCaptchaCode(code);
  };

  const generateBackgroundImage = () => {
    const patterns = ["pattern-dot", "pattern-dash", "pattern-cross-dots"];
    const randomIndex = Math.floor(Math.random() * patterns.length);
    const patternClass = patterns[randomIndex];
    setBackgroundImage(patternClass);
  };

  const validateEmail = (e) => {
    setEmail(e.target.value);
    var email = e.target.value;
    if (validator.isEmail(email) || validator.isEmpty(email)) {
      if (validator.isEmpty(email)) {
        setEmailError("");
        setIsEmailValidated(false);
      } else {
        setEmailError("");
        setIsEmailValidated(true);
      }
    } else {
      setEmailError("Enter a valid Email!");
      setIsEmailValidated(false);
    }
  };

  const validateCaptcha = (e) => {
    setCaptcha(e.target.value);
    if (e.target.value.trim() === "") {
      setCaptchaError("Enter the CAPTCHA code!");
    } else if (e.target.value.trim() !== captchaCode) {
      setCaptchaError("Enter the valid CAPTCHA code!");
    } else {
      setCaptchaError("");
    }
  };

  const refreshCaptcha = () => {
    generateCaptcha();
    generateBackgroundImage();
  };

  const startTimer = () => {
    let seconds = 30;
    setIsTimerRunning(true);
    const countdown = setInterval(() => {
      seconds--;
      setTimer(seconds);
      if (seconds === 0) {
        clearInterval(countdown);
        setIsTimerRunning(false);
      }
    }, 500);
  };
  const handleKeyPress = (e) => {
    if (e && e.key === "Enter") {
      e.preventDefault();

      // Refresh CAPTCHA code
      // refreshCaptcha();

      // Trigger login process
      sendResetLink();
    }
  };
  const sendResetLink = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!isEmailValidated || captcha.trim() === "") {
      setEmailError(isEmailValidated ? "" : "Enter a valid Email!");
      setCaptchaError(captcha.trim() === "" ? "Enter the CAPTCHA code!" : "");
      return;
    }

    if (captcha.trim() !== captchaCode) {
      setCaptchaError("Enter the valid CAPTCHA code!");
      return;
    }
    setLoading(true);
    axios
      .get(`${baseUrl}${API_PATH.FORGET_PASSWORD}${email}`)
      .then((res) => {
        setLoading(false);
        if (res.data.IsSuccess) {
          toast.success(res.data?.Message?.Message ?? "");
          setIsLinkSent(true);
          startTimer();
        } else {
          toast.error(res.data?.Message?.Message ?? "");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handlePaste = (e) => {
    e.preventDefault();
  };

  return (
    <Grid
      container
      direction={{
        lg: "row",
        md: "row",
        sm: "column-reverse",
        xs: "column-reverse",
      }}
      sx={{
        flexWrap: "inherit",
      }}
    >
      <Grid
        item
        lg={5}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "68%",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{
              marginBottom: "2%",
            }}
          >
            Forget Password
          </Typography>
          <form>
            <FormGroup>
              <FormLabel
                id="Email"
                sx={{
                  fontSize: "80%",
                  margin: "0",
                }}
              >
                Email
              </FormLabel>
              <TextField
                fullWidth
                size="small"
                id="Email"
                name="Email"
                onChange={validateEmail}
                sx={{
                  marginBottom: "3%",
                }}
                // error={fieldError["Email"]}
                // helperText={fieldError["Email"]}
              />

              <Typography className="error">{emailError}</Typography>
              {isEmailValidated && (
                <>
                  <FormLabel
                    id="captcha"
                    sx={{
                      fontSize: "80%",
                      margin: "0",
                    }}
                  >
                    CAPTCHA
                  </FormLabel>
                  <TextField
                    fullWidth
                    size="small"
                    id="captcha"
                    name="captcha"
                    onChange={validateCaptcha}
                    value={captcha}
                    error={!!captchaError}
                    onPaste={handlePaste}
                    helperText={captchaError}
                    onKeyDown={handleKeyPress}
                  />

                  <div className="captcha-container">
                    <div className={`captcha-code ${backgroundImage}`}>
                      {captchaCode.split("").map((char, index) => (
                        <span key={index}>{char}</span>
                      ))}
                    </div>
                    <button className="refresh-button" onClick={refreshCaptcha}>
                      <FaSyncAlt />
                    </button>
                  </div>
                </>
              )}
              <Typography
                variant="caption"
                color="primary"
                align="right"
                sx={{
                  cursor: "pointer",
                }}
              >
                <span
                  onClick={() => {
                    navigate(Routeconstant.LOGIN);
                  }}
                >
                  {" "}
                  Login
                </span>
              </Typography>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={sendResetLink}
                disabled={isLinkSent}
              >
                {loading ? (
                  <CircularProgress
                    size="2rem"
                    style={{ color: "var(--color-miscellaneous-4)" }}
                  />
                ) : isLinkSent ? (
                  `Link Sent (${timer}s)`
                ) : (
                  "Send Reset Link"
                )}
              </Button>
            </FormGroup>
          </form>
        </Box>
      </Grid>

      <Grid
        item
        lg={7}
        md={12}
        sm={12}
        xs={12}
        className="second-section"
        key={1}
        style={{ position: "relative" }}
      >
        {/* --specific changes for 1Up cargo  */}
        {/* <Grid container className="login-background-opacity "></Grid> */}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "1rem",
              position: "absolute",
              top: "40%",
            }}
          >
            <img
              src={urlResolver(`assets/headerLogo.png`)}
              style={{ width: "30vw" }}
            />
          </div>
        </Grid>
        {/* ----------------  */}
      </Grid>
    </Grid>
  );
};

export default ForgetPassword;
