import React, { useContext, useState } from "react";
import { basename } from "../../../Config";
import { Outlet, useLocation } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../layout.css";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { AuthContext } from "../../../store/context/AuthProvider";
import OrderListTable from "../../../screens/order/listview/OrderListTable.js";
import OrderDetailsListView from "../../../screens/order/listview/OrderDetailsListView.js";
import Tableindex from "../../../screens/order/listview/Tableindex.js";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import urlResolver from "../../../utils/urlResolver";

function LayoutOrder({ children }) {
  const location = useLocation();
  const context = useContext(AuthContext);
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  return (
    <>
      <div
        className={
          location.pathname.includes(Routeconstant.DETAILS)
            ? "container-fluid shipment-top-div shipment-card-div"
            : "container-fluid shipment-top-div"
        }
      >
        {context.gridViewOrder === true ? (
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="d-flex justify-content-between col-xxl-4 col-xs-12 col-md-6 col-lg-5 col-xl-4 align-items-center layout-select-box"
              style={{ paddingLeft: "0px", paddingRight: "30px" }}
            >
              <p className="layout-head">Order</p>

              <Stack spacing={1}>
                <Chip
                  label="Export to Excel"
                  variant="outlined"
                  onClick={handleClick}
                />
              </Stack>
            </div>
            <div className="d-flex  justify-content-between align-items-center py-2 layout-head-section">
              <div
                className={
                  context.gridViewOrder
                    ? "icons-shipment-page-inactive d-flex justify-content-center align-items-center mr-2"
                    : "icons-shipment-page d-flex justify-content-center align-items-center mr-2"
                }
                onClick={() => {
                  context.setGridViewOrder(false);
                }}
              >
                {context.gridViewOrder ? (
                  <img src={urlResolver("assets/ListViewBlue.svg")} />
                ) : (
                  <img src={urlResolver("assets/ListViewWhite.svg")} />
                )}
              </div>
              <div
                className={
                  context.gridViewOrder
                    ? "icons-shipment-page d-flex justify-content-center align-items-center"
                    : "icons-shipment-page-inactive d-flex justify-content-center align-items-center"
                }
                onClick={() => {
                  context.setGridViewOrder(true);
                }}
              >
                {context.gridViewOrder ? (
                  <img src={urlResolver("assets/GridViewWhite.svg")} />
                ) : (
                  <img src={urlResolver("assets/GridViewBlue.svg")} />
                )}
              </div>
            </div>
          </div>
        ) : context.gridViewOrder === false &&
          location.pathname === Routeconstant.ORDER_LISTING ? (
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="d-flex justify-content-between col-xxl-4 col-xs-12 col-md-6 col-lg-5 col-xl-4 align-items-center layout-select-box"
              style={{ paddingLeft: "0px", paddingRight: "30px" }}
            >
              <p className="layout-head">Order</p>

              <Stack spacing={1}>
                <Chip
                  label="Export to Excel"
                  variant="outlined"
                  onClick={handleClick}
                />
              </Stack>
            </div>
            <div className="d-flex justify-content-between align-items-center py-2 layout-head-section">
              <div
                className={
                  context.gridViewOrder
                    ? "icons-shipment-page-inactive d-flex justify-content-center align-items-center mr-2"
                    : "icons-shipment-page d-flex justify-content-center align-items-center mr-2"
                }
                onClick={() => {
                  context.setGridViewOrder(false);
                }}
              >
                {context.gridViewOrder ? (
                  <img src={urlResolver("assets/ListViewBlue.svg")} />
                ) : (
                  <img src={urlResolver("assets/ListViewWhite.svg")} />
                )}
              </div>
              <div
                className={
                  context.gridViewOrder
                    ? "icons-shipment-page d-flex justify-content-center align-items-center border-radius-right"
                    : "icons-shipment-page-inactive d-flex justify-content-center align-items-center border-radius-right"
                }
                onClick={() => {
                  context.setGridViewOrder(true);
                }}
              >
                {context.gridViewOrder ? (
                  <img src={urlResolver("assets/GridViewWhite.svg")} />
                ) : (
                  <img src={urlResolver("assets/GridViewBlue.svg")} />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {context.gridViewOrder ? (
        <div className="container-fluid shipment-bottom-div">
          <div className="row">
            <div
              className={
                location.pathname.includes(Routeconstant.DETAILS)
                  ? "col-xs-12 col-md-6 col-lg-5 col-xl-4 shipment-card-div"
                  : "col-xs-12 col-md-6 col-lg-5 col-xl-4 "
              }
            >
              {children}
            </div>

            {location.pathname === Routeconstant.ORDER_LISTING ? (
              <div className="col-xs-12 col-md-6 col-lg-7 col-xl-8 d-md-flex flex-column justify-content-center align-items-center d-none d-sm-none select-shipment-div">
                <img
                  src={urlResolver("assets/SelectShipmentMain.svg")}
                  style={{ width: "inherit" }}
                />
                <p className="select-shipment-details">
                  Select a shipment to see details
                </p>
              </div>
            ) : (
              <div className="col-xs-12 col-md-6 col-lg-7 col-xl-8">
                <Outlet />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {location.pathname === `${Routeconstant.ORDER_LISTING}` ? (
            <Tableindex />
          ) : (
            <OrderDetailsListView />
          )}
        </>
      )}
    </>
  );
}

export default LayoutOrder;
