import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from "chart.js";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import NodatafoundComp from "../NodatafoundComp";
import { AuthContext } from "../../store/context/AuthProvider";
import { useContext } from "react";
ChartJS.register(ArcElement, Tooltip, Legend);
export default function HalpPieChartArrival({ filterOptions, updateData }) {
  const [chartData, setChartData] = useState([]);
  const [nodataFound, setNoDataFound] = useState(false);
  const context = useContext(AuthContext);
  const data = {
    labels: ["Greater Then", "Less Then"],
    datasets: [
      {
        label: "Count",
        data: chartData,
        backgroundColor: ["#0A2D44", "#236C9C"],
        borderColor: ["#0A2D44", "#236C9C"],
        circumference: 180,
        rotation: 270,
      },
    ],
  };
  const getHalfChart = () => {
    const body = {
      ShpEtdFrom: "",
      ShpEtdTo: "",
      ShpEtaFrom: "",
      ShpEtaTo: "",
      ShpTransportMode: filterOptions.filterMode
        ? filterOptions.filterMode
        : "AIR",
      ShpOriginPortCode: "",
      ShpOriginPortName: "",
      ShpDestinationPortCode: "",
      ShpConsignorCode: "",
      ShpConsigneeCode: "",
      IncoTerm: "",
      ShpCarrierCode: filterOptions?.filterCarrier
        ? filterOptions?.filterCarrier
        : "",
      Year: filterOptions?.filterYear ? parseInt(filterOptions?.filterYear) : 0,
    };
    instance
      .post(API_PATH.GET_PICKUP_ARRIVAL, body)
      .then((res) => {
        console.log("res Dep", res.data);
        let temp = [];
        if (
          res.data[0].GreaterThenSettingValue === 0 &&
          res.data[0].LessThenEqualSettingValue == 0
        ) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
          temp.push(res.data[0].GreaterThenSettingValue);
          temp.push(res.data[0].LessThenEqualSettingValue);
          setChartData(temp);
        }
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err);
      });
  };
  useEffect(() => {
    if (context.filterLoaded) getHalfChart();
  }, [filterOptions, updateData, context.filterLoaded]);
  return (
    <>
      {nodataFound ? (
        <NodatafoundComp />
      ) : (
        <Grid
          container
          spacing={2}
          sx={
            {
              // paddingRight: "1%",
            }
          }
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <Doughnut
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: true, // Hide legend
                },
              }}
            ></Doughnut>
          </Grid>
        </Grid>
      )}
    </>
  );
}
