import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Skeleton } from "@mui/material";
import { basename } from "../../../Config";
import { red } from "@mui/material/colors";
import urlResolver from "../../../utils/urlResolver";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Mapcomp(props) {
  const location = useLocation();
  const [locationData, setLocationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState(new props.google.maps.LatLngBounds());
  const [pinData, setPinData] = useState({});
  const [isMarkerBlinking, setIsMarkerBlinking] = useState(true);
  const [curvy, setCurvy] = useState(false);

  // const shipment = location.state.data;

  const handleMarkerClick = () => {
    setIsMarkerBlinking(!isMarkerBlinking);
  };

  useEffect(() => {
    if (props?.shipmentDetails?.TransportMode === "AIR") setCurvy(true);
    getLocation();
  }, [location?.state?.ShipmentId]);

  const getLocation = () => {
    setLoading(true);
    const body = {
      ShipmentId: location?.state?.ShipmentId ?? "",
      TransportMode:
        props?.shipmentDetails?.TransportMode ??
        // location?.state?.data?.TransportMode ??
        "",
      // TransportMode: "",

      SCAC: props?.shipmentDetails?.SACCode ?? "",
      type: "BL",
      OriginUNLOCO: props?.shipmentDetails?.OriginPortCode,
      OriginPortName: props?.shipmentDetails?.OriginPortName ?? "",
      BlNo: props?.shipmentDetails?.MasterBillNumber?.length
        ? props?.shipmentDetails?.MasterBillNumber[0]
        : "",
      DestinationUNLOCO: props?.shipmentDetails?.DestinationPortCode ?? "",
      DestinationPortName: props?.shipmentDetails?.DestinationPortName ?? "",
      // ShipmentStatus: props?.shipmentDetails?.ShipmentStatus
      //   ? props?.shipmentDetails?.ShipmentStatusDescription[0]
      //   : "",,
      ShipmentStatus: props?.shipmentDetails?.ShipmentStatus ?? "",
      ETD: props?.shipmentDetails?.ETDDate ?? "",
      ETA: props?.shipmentDetails?.ETADate ?? "",
    };
    instance
      .post(API_PATH.GET_ROUTE_LOCATION, body)
      .then((res) => {
        setTimeout(() => {
          getboundsdata(res.data[0].Marker ?? []);
        }, 300);
        setLocationData(res.data[0] ?? {});
        setPinData(res.data[0].Pin ?? {});
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getboundsdata = (point) => {
    const bounds = new props.google.maps.LatLngBounds();
    if (point.length >= 3) {
      point.map((item) => {
        if (item.Locationtype !== "DESTINATION") {
          bounds.extend({ lat: item.Lat, lng: item.Lng });
        }
      });
    } else {
      point.map((item) => {
        bounds.extend({ lat: item.Lat, lng: item.Lng });
      });
    }
    setPoints(bounds);
  };

  const pathOptions = {
    geodesic: curvy,
    strokeColor: "var(--color-primary)",
    strokeOpacity: 1,
    icons: [
      {
        icon: {
          path: props.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          strokeOpacity: 0,
          fillColor: "var(--color-primary)",
          fillOpacity: 1.0,
          scale: 4,
        },
        repeat: "80px",
      },
    ],
  };

  return (
    <>
      <div className="locate-div">
        {loading ? (
          <div className="skeleton-div">
            <Skeleton style={{ height: "29rem", marginTop: "1rem" }} />
          </div>
        ) : (
          <>
            <Map
              icon={{
                url: urlResolver("assets/location.gif"),
                scaledSize: new window.google.maps.Size(20, 31),
              }}
              google={props.google}
              zoom={14}
              initialCenter={{
                lat:
                  locationData?.Marker?.length === 2
                    ? (locationData?.Marker[0]?.Lat +
                        locationData?.Marker[1]?.Lat) /
                      2
                    : 42.39,
                lng:
                  locationData?.Marker?.length === 2
                    ? (locationData?.Marker[0]?.lng +
                        locationData?.Marker[1]?.lng) /
                      2
                    : -72.52,
              }}
              className="scdc"
              bounds={points}
            >
              {locationData.Marker &&
                locationData?.Marker.map((item, index) => {
                  return (
                    <Marker
                      icon={{
                        url: urlResolver("assets/marker-new.png"),
                        scaledSize: new window.google.maps.Size(20, 31),
                      }}
                      key={item}
                      position={{ lat: item.Lat, lng: item.Lng }}
                      title={item?.Name}
                      name={item?.Locationtype}
                    />
                  );
                })}
              <Polyline
                path={locationData?.routes ?? []}
                strokeColor="#0000FF"
                strokeOpacity={0.8}
                options={pathOptions}
                strokeWeight={2}
              />
              <Marker
                icon={{
                  url: urlResolver("assets/location.gif"),
                  scaledSize: new window.google.maps.Size(20, 31),
                }}
                position={{
                  lat: pinData.lat,
                  lng: pinData.lng,
                }}
                // animation={
                //   isMarkerBlinking ? props.google.maps.Animation.BOUNCE : null
                // }
                // onClick={handleMarkerClick}
              />
              {/* <Marker
                icon={{
                  url: urlResolver("assets/location.png`,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                
                // title={`${item?.CountryName ?? "N/A"}(${item?.Count ?? "N/A"})`}
                // name={`${item?.CountryName ?? "N/A"}(${item?.Count ?? "N/A"})`}
                position={{
                  lat: pinData.lat,
                  lng: pinData.lng,
                  // lat: 40.9,
                  // lng: -74.067,
                }}
              /> */}
            </Map>
          </>
        )}
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDpfWeLTpZm0lhuv7_s-8cvKv_8sxJUHD0",
})(React.memo(Mapcomp));
