import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { basename, LOCAL_STORAGE_KEY } from "../../Config";
import AdbIcon from "@mui/icons-material/Adb";
import "./Header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import SettingsIcon from "@mui/icons-material/Settings";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AuthContext } from "../../store/context/AuthProvider";
import ChangePasswordModal from "../model/ChangePasswordModal";
import DocUploadShipmentSidebar from "../sidebar/DocUploadShipmentSidebar";
import HomeIcon from "@mui/icons-material/Home";
import { useState } from "react";
import Notification from "../../screens/notifications/Index";
import urlResolver from "../../utils/urlResolver";
const pages = ["Products", "Pricing", "Blog"];
const settings = ["Change Password", "Logout"];

const Header = () => {
  const location = useLocation();
  const context = React.useContext(AuthContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const displayData = [
    {
      module: "shipment",
      children: [
        {
          displayName: "Shipment Listing",
          route: Routeconstant.SHIPMENT_LISTING,
        },
        {
          displayName: "Shipment Analytics",
          route: Routeconstant.SHIPMENT_ANALYTICS,
        },
      ],
    },
    {
      module: "newshipment",
      children: [
        {
          displayName: "",
        },
      ],
    },
    // {
    //   module: "order",
    //   children: [
    //     {
    //       displayName: "Order Listing",
    //       route: Routeconstant.ORDER_LISTING,
    //     },
    //     {
    //       displayName: "Order Analytics",
    //       route: Routeconstant.ORDER_ANALYTICS,
    //     },
    //   ],
    // },
  ];

  const notificationDrawer = () => {
    setNotificationOpen(true);
  };

  return (
    // <AppBar position="static" color="transparent">
    //   <Container maxWidth="xl">
    //     <Toolbar disableGutters>
    //       {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
    //       <Typography
    //         variant="h6"
    //         noWrap
    //         component="a"
    //         href="/"
    //         sx={{
    //           mr: 2,
    //           display: { xs: "none", md: "flex" },
    //           fontFamily: "monospace",
    //           fontWeight: 700,
    //           letterSpacing: ".3rem",
    //           color: "inherit",
    //           textDecoration: "none",
    //         }}
    //       >
    //         <img src={urlResolver("assets/headerLogo.png`} />
    //       </Typography>

    //       <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
    //         <IconButton
    //           size="large"
    //           aria-label="account of current user"
    //           aria-controls="menu-appbar"
    //           aria-haspopup="true"
    //           onClick={handleOpenNavMenu}
    //           color="inherit"
    //         >
    //           <MenuIcon />
    //         </IconButton>
    //         <Menu
    //           id="menu-appbar"
    //           anchorEl={anchorElNav}
    //           anchorOrigin={{
    //             vertical: "bottom",
    //             horizontal: "left",
    //           }}
    //           keepMounted
    //           transformOrigin={{
    //             vertical: "top",
    //             horizontal: "left",
    //           }}
    //           open={Boolean(anchorElNav)}
    //           onClose={handleCloseNavMenu}
    //           sx={{
    //             display: { xs: "block", md: "none" },
    //           }}
    //         >
    //           {pages.map((page) => (
    //             <MenuItem key={page} onClick={handleCloseNavMenu}>
    //               <Typography textAlign="center">{page}</Typography>
    //             </MenuItem>
    //           ))}
    //         </Menu>
    //       </Box>
    //       {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
    //       <Typography
    //         variant="h5"
    //         noWrap
    //         component="a"
    //         href=""
    //         sx={{
    //           mr: 2,
    //           display: { xs: "flex", md: "none" },
    //           flexGrow: 1,
    //           fontFamily: "monospace",
    //           fontWeight: 700,
    //           letterSpacing: ".3rem",
    //           color: "inherit",
    //           textDecoration: "none",
    //         }}
    //       >
    //         LOGO
    //       </Typography> */}
    //       {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
    //         {pages.map((page) => (
    //           <Button
    //             key={page}
    //             onClick={handleCloseNavMenu}
    //             sx={{ my: 2, color: "white", display: "block" }}
    //           >
    //             {page}
    //           </Button>
    //         ))}
    //       </Box> */}

    //       {/* <Box sx={{ flexGrow: 0 }}>
    //         <Tooltip title="Open settings">
    //           <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
    //             <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
    //           </IconButton>
    //         </Tooltip>
    //         <Menu
    //           sx={{ mt: "45px" }}
    //           id="menu-appbar"
    //           anchorEl={anchorElUser}
    //           anchorOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //           }}
    //           keepMounted
    //           transformOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //           }}
    //           open={Boolean(anchorElUser)}
    //           onClose={handleCloseUserMenu}
    //         >
    //           {settings.map((setting) => (
    //             <MenuItem key={setting} onClick={handleCloseUserMenu}>
    //               <Typography textAlign="center">{setting}</Typography>
    //             </MenuItem>
    //           ))}
    //         </Menu>
    //       </Box> */}
    //       <Stack direction="row" spacing={1} className="profile-icon">
    //         <Chip
    //           avatar={<Avatar color="secondary">P</Avatar>}
    //           label="Prakash"
    //         />
    //         <Divider orientation="vertical" variant="middle" flexItem />
    //         <SettingsIcon />
    //       </Stack>
    //     </Toolbar>
    //   </Container>
    // </AppBar>
    <>
      <ChangePasswordModal handleClose={handleClose} open={open} />

      {notificationOpen ? (
        <Notification
          notificationOpen={notificationOpen}
          setNotificationOpen={setNotificationOpen}
        />
      ) : null}
      <AppBar position="static" color="transparent">
        {/* <Container> */}
        <Toolbar disableGutters>
          <div className="header-logo">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <img src={urlResolver("assets/header_icon.svg")} />
            </IconButton>

            {/* menu item for larger screen */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Link
                to={Routeconstant.LANDING_PAGES}
                className="links-react-router"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <IconButton
                      sx={{
                        backgroundColor: "#d0eeff",
                      }}
                    >
                      <HomeIcon color="primary" />
                    </IconButton>

                    <p
                      className="header-text"
                      style={{
                        marginLeft: "12%",
                      }}
                    >
                      Dashboard
                    </p>
                  </Typography>
                </MenuItem>
              </Link>
              <Link
                to={Routeconstant.SHIPMENT_LISTING}
                className="links-react-router"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/ShipmentManagementIcon.svg")}
                    />
                    <span className="header-text">Shipment Management</span>
                  </Typography>
                </MenuItem>
              </Link>
              {/* <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  to={Routeconstant.ORDER_LISTING}
                  className="links-react-router"
                >
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/OrderManagement.svg")}
                    />
                    <p className="header-text">Order Management</p>
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  // to={Routeconstant.ENQUIRY}
                  className="links-react-router"
                >
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/OrderShipmentInquiry.svg")}
                    />
                    <p className="header-text">Order / Shipment Enquiry</p>
                  </Typography>
                </Link>
              </MenuItem> */}
              {/* <Link to={Routeconstant.RMS} className="links-react-router">
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/StatementIcon.svg")}
                    />
                    <p className="header-text">RMS</p>
                  </Typography>
                </MenuItem>
              </Link> */}
              <Link to={Routeconstant.STATEMENT} className="links-react-router">
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/StatementIcon.svg")}
                    />
                    <p className="header-text">Statement of Account</p>
                  </Typography>
                </MenuItem>
              </Link>
              <Link to={Routeconstant.ENQUIRY} className="links-react-router">
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/UserLogsIcon.svg")}
                    />
                    <p className="header-text">General Enquiry</p>
                  </Typography>
                </MenuItem>
              </Link>
              {context?.userData?.Role?.includes("Admin") && (
                <Link
                  to={Routeconstant.USER_LOGS}
                  className="links-react-router"
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      className="d-flex align-items-center"
                    >
                      <img
                        className="icon-menuitem"
                        src={urlResolver("assets/UserLogsIcon.svg")}
                      />
                      <p className="header-text">User Logs</p>
                    </Typography>
                  </MenuItem>
                </Link>
              )}
            </Menu>

            {/* menu item for smaller screen */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <Link
                to={Routeconstant.LANDING_PAGES}
                className="links-react-router"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <IconButton
                      sx={{
                        backgroundColor: "#d0eeff",
                      }}
                    >
                      <HomeIcon color="primary" />
                    </IconButton>

                    <p
                      className="header-text"
                      style={{
                        marginLeft: "12%",
                      }}
                    >
                      Dashboard
                    </p>
                  </Typography>
                </MenuItem>
              </Link>
              <Accordion className="accordian-items">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex align-items-center">
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/ShipmentManagementIcon.svg")}
                    />
                    <span className="header-text">Shipment Management</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {displayData.map((item, ind) => {
                    return (
                      <>
                        {item.module === "shipment" && (
                          <ul key={ind}>
                            {item.children.map((i, index) => {
                              return (
                                <Link
                                  key={index}
                                  to={i.route}
                                  style={{ textAlign: "left" }}
                                >
                                  <li
                                    onClick={handleCloseNavMenu}
                                    className="header-text"
                                  >
                                    {i.displayName}
                                  </li>
                                </Link>
                              );
                            })}
                          </ul>
                        )}
                      </>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
              {/* <Accordion className="accordian-items">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex">
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/OrderManagement.svg")}
                    />
                    <p className="header-text">Order Management</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {displayData.map((item, ind) => {
                    return (
                      <>
                        {item.module === "order" ? (
                          <ul key={ind}>
                            {item.children.map((i, index) => {
                              return (
                                <Link
                                  key={index}
                                  to={i.route}
                                  style={{ textAlign: "left" }}
                                >
                                  <li
                                    onClick={handleCloseNavMenu}
                                    className="header-text"
                                  >
                                    {i.displayName}
                                  </li>
                                </Link>
                              );
                            })}
                          </ul>
                        ) : null}
                      </>
                    );
                  })}
                </AccordionDetails>
              </Accordion> */}
              {/* <Accordion className="accordian-items">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex">
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/OrderShipmentInquiry.svg")}
                    />
                    <p className="header-text">Order / Shipment Enquiry</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion> */}
              {/* <Link to={Routeconstant.RMS} className="links-react-router">
                <div className="d-flex mobile-menu">
                  <img
                    className="icon-menuitem"
                    src={urlResolver("assets/StatementIcon.svg")}
                  />
                  <p className="header-text">RMS</p>
                </div>
              </Link> */}
              <Link to={Routeconstant.STATEMENT} className="links-react-router">
                <div
                  className="d-flex mobile-menu align-items-center"
                  onClick={handleCloseNavMenu}
                >
                  <img
                    className="icon-menuitem"
                    src={urlResolver("assets/StatementIcon.svg")}
                  />
                  <p className="header-text">Statement of Account</p>
                </div>
              </Link>
              {/* <Accordion className="accordian-items">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                > */}
              <Link to={Routeconstant.ENQUIRY} className="links-react-router">
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/UserLogsIcon.svg")}
                    />
                    <p className="header-text">General Enquiry</p>
                  </Typography>
                </MenuItem>
              </Link>
              {context?.userData?.Role?.includes("Admin") && (
                <Link
                  to={Routeconstant.USER_LOGS}
                  className="links-react-router"
                  onClick={handleCloseNavMenu}
                >
                  <div className="d-flex mobile-menu align-items-center">
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/UserLogsIcon.svg")}
                    />
                    <p className="header-text">User Logs</p>
                  </div>
                </Link>
              )}

              {/* </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion> */}
            </Menu>
            {/* <Typography
              variant="h6"
              noWrap
              component="a"
              // href="/"
              sx={{
                mr: 2,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
              onClick={() => {
                navigate(Routeconstant.LANDING_PAGES);
              }}
            > */}
            <a href={`https://www.1upcargo.com/`} target="_blank">
              <IconButton
                onClick={() => {
                  // navigate("https://softfreightlogic.com/");
                }}
              >
                <img
                  src={urlResolver(`assets/headerLogo.png`)}
                  style={{ height: "2rem" }}
                />
              </IconButton>
            </a>
            {/* </Typography> */}
          </div>

          {/* sub menu item only for larger screen */}
          <Typography sx={{ display: { xs: "none", md: "flex" } }}>
            <ul className="navbar-nav d-flex flex-row">
              {displayData.map((item, index) => {
                if (location.pathname.includes(item.module)) {
                  return (
                    <>
                      {item.children.map((i, ind) => {
                        return (
                          <li className="nav-item mr-5" key={ind}>
                            {i.displayName === "Order Analytics" ? (
                              <Link
                                // to={i.route}
                                className="links-react-router "
                              >
                                <div
                                  className={
                                    location.pathname.includes(i.route)
                                      ? "pt-0 pb-0 dark-color"
                                      : "pt-0 pb-0 header-sub-text"
                                  }
                                >
                                  <div
                                    className={
                                      location.pathname.includes(i.route)
                                        ? "header-border"
                                        : ""
                                    }
                                    style={{ margin: "0" }}
                                  ></div>
                                  {i.displayName}
                                </div>
                              </Link>
                            ) : (
                              <Link
                                to={i.route}
                                className="links-react-router "
                              >
                                <div
                                  className={
                                    location.pathname.includes(i.route)
                                      ? "pt-0 pb-0 dark-color"
                                      : "pt-0 pb-0 header-sub-text"
                                  }
                                >
                                  <div
                                    className={
                                      location.pathname.includes(i.route)
                                        ? "header-border"
                                        : ""
                                    }
                                    style={{ margin: "0" }}
                                  ></div>
                                  {i.displayName}
                                </div>
                              </Link>
                            )}
                          </li>
                        );
                      })}
                    </>
                  );
                }
              })}
            </ul>
          </Typography>

          {/* profile icon for larger screen */}
          <Typography sx={{ display: { xs: "none", md: "flex" } }}>
            <div className="d-flex align-items-center">
              <Box
                sx={{ flexGrow: 0 }}
                className="profile-icon d-flex justify-content-center align-items-center"
              >
                {/* <Tooltip title="Open settings"> */}
                {context.userData.Name && (
                  <IconButton sx={{ p: 0, border: "none" }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={`https://ui-avatars.com/api/?name=${
                        context.userData.Name.split("")[0]
                      }`}
                      style={{ height: "35px", width: "35px" }}
                    />
                  </IconButton>
                )}
                {/* </Tooltip> */}
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        onClick={() => {
                          if (setting === "Change Password") {
                            handleClickOpen();
                          } else if (setting === "Logout") {
                            localStorage.removeItem(LOCAL_STORAGE_KEY);
                            // window.location.reload();
                            context.setToken({});
                            context.setUserData({});
                            context.setIslogin(false);
                            navigate(Routeconstant.LOGIN);
                          }
                        }}
                      >
                        {setting}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
                <div className="d-flex">
                  <p
                    style={{
                      margin: 0,
                      borderRight: "1px solid #D0DEF0",
                      padding: "0 1rem",
                      color: "var(--color-primary)",
                    }}
                  >
                    {context?.userData?.Name ?? ""}
                  </p>
                  <div className="settings-icon">
                    <SettingsIcon
                      onClick={handleOpenUserMenu}
                      style={{
                        color: "#D0DEF0",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </Box>

              {/* <div className="px-3" onClick={notificationDrawer}>
                {" "}
                <NotificationsNoneIcon className="header-notification" />
              </div> */}
            </div>
          </Typography>

          {/* profile icon for smaller screen */}
          <Typography sx={{ display: { xs: "flex", md: "none" } }}>
            {/* <div className="px-3" onClick={notificationDrawer}>
              <NotificationsNoneIcon
                style={{
                  fontSize: "3rem",
                  color: "var(--color-primary)",
                  cursor: "pointer",
                }}
              />
            </div> */}

            <div className="d-flex align-items-center">
              <Box
                sx={{ flexGrow: 0 }}
                className="profile-icon d-flex justify-content-center align-items-center"
              >
                {context.userData.Name && (
                  <IconButton sx={{ p: 0 }}>
                    <IconButton sx={{ p: 0, border: "none" }}>
                      <Avatar
                        onClick={handleOpenUserMenu}
                        alt="Remy Sharp"
                        src={`https://ui-avatars.com/api/?name=${
                          context.userData.Name.split("")[0]
                        }`}
                        style={{ height: "35px", width: "35px" }}
                      />
                    </IconButton>
                  </IconButton>
                )}

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={(event) => {
                        const selectedItem =
                          event.target.querySelector("#setting").innerHTML;
                        if (selectedItem === "Change Password") {
                          handleClickOpen();
                        } else if (selectedItem === "Logout") {
                          localStorage.removeItem(LOCAL_STORAGE_KEY);
                          context.setToken({});
                          context.setUserData({});
                          context.setIslogin(false);
                          navigate(Routeconstant.LOGIN);
                        }
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography
                        id={"setting"}
                        textAlign="center"
                        onClick={() => {
                          if (setting === "Change Password") {
                            handleClickOpen();
                          } else if (setting === "Logout") {
                            localStorage.removeItem(LOCAL_STORAGE_KEY);
                            context.setToken({});
                            context.setUserData({});
                            context.setIslogin(false);
                            navigate(Routeconstant.LOGIN);
                          }
                        }}
                      >
                        {setting}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </Typography>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
      {/* <DocUploadShipmentSidebar /> */}
    </>
  );
};
export default Header;
