import { customerName } from "../Config";

let permission = { financesAccess: true };
const accessGiven = () => {
  switch (customerName) {
    case "SFLInsight":
      return permission;
    case "Brokeman":
      permission.financesAccess = false;
      return permission;
    default:
      return permission;
  }
};
export default accessGiven;
