import React, { useState } from "react";
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
} from "@mui/material";
import { DateFormat } from "../../../utils//moment";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { API_PATH } from "../../../constant/apiPath";
import instance from "../../../api/Interceptor";
import { useEffect } from "react";
const MajorMilestone = () => {
  const location = useLocation();
  const [valueOptions, setvalueOptions] = useState("");
  const [containerMilestoneData, setContainerMilestoneData] = useState([]);
  const [options, setoptions] = useState([]);
  const [visibledata, setVisibledata] = useState([]);
  const [loading, setLoading] = useState(false);
  const dateCheck = true;
  const shipment = location.state.data;
  const [checkColor, setCheckColor] = useState([]);
  const [locationIdArray, setLocationIdArray] = useState([]);

  // --------------------------------------------------------------------------
  const [locationDetails, setLocationDetails] = useState([]);
  const [checkColor1, setCheckColor1] = useState(null);
  console.log(shipment, " ");
  console.log(visibledata, "locationDetails this is visisble data");
  // console.log(shipment, "response sdf");
  useEffect(() => {
    getContainerMilestoneRoutes();
  }, [location?.state?.ShipmentId]);

  const getContainerMilestoneRoutes = () => {
    setLoading(true);
    const body = {
      sealine: shipment?.SACCode ?? "",
      mbl: shipment?.MasterBillNumber?.length
        ? shipment?.MasterBillNumber[0]
        : "",
      type: "bl",
    };

    console.log(body, "response body  ");
    instance
      .post(API_PATH.GET_CONTAINER_MILESTONE_ROUTES, body)
      .then((res) => {
        setContainerMilestoneData(res?.data?.containers ?? []);
        setLocationDetails(res?.data?.locations ?? []);

        // for old api call
        // setContainerMilestoneData(res?.data[0] ?? {});
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const data = containerMilestoneData;
    let arr = [];
    data.forEach((element) => {
      arr.push(element.number);
    });
    // new format call
    setoptions(arr);
    setvalueOptions(data[0]?.number);
    dataToShow(data[0]?.number);
    // console.log(" response data --------------->", data, arr);
    // old data format call
    // const data = Object.keys(containerMilestoneData);
    // setoptions(data);
    // setvalueOptions(data[0]);
    // dataToShow(data[0]);
  }, [containerMilestoneData]);

  const dataToShow = (value) => {
    console.log("new data --> parameter", value);
    let toShowData = containerMilestoneData?.filter((obj) => {
      if (obj.number === value) {
        return obj;
      }
    });
    let locationId = new Set();
    toShowData[0]?.events.forEach((item) => {
      locationId.add(item.location);
    });

    setLocationIdArray(Array.from(locationId));
    setVisibledata(toShowData[0]?.events);
  };

  const handleChange = (event) => {
    console.log("Dropdown Event", event);
  };

  // useEffect(() => {
  //   let temp2 = [];
  //   if (visibledata) {
  //     visibledata.map((item, ind) => {
  //       temp2.push({ color: "" });
  //     });
  //     visibledata.map((item, index) => {
  //       // if (item.milestonecollection) {
  //       //   item.milestonecollection.map((eachmilestone) => {
  //       //     if (eachmilestone.actual) {
  //       //       temp2[index].color = "green";
  //       //     }
  //       //   });
  //       // }
  //     });
  //     console.log("temp2", temp2);
  //     setCheckColor(temp2);
  //   }

  //   // let temp = temp2.reverse();
  //   // let isValid = false;
  //   // let res = [];
  //   // temp.map((x) => {
  //   //   if (!isValid && x !== null) {
  //   //     isValid = true;
  //   //   }
  //   //   res.push(isValid ? "success" : "grey");
  //   // });
  //   // let rev = res.reverse();
  //   // setCheckColor(rev);
  // }, [visibledata]);
  // console.log("visibledata", visibledata);

  useEffect(() => {
    const arrOfcolor = new Array(locationDetails?.length).fill(0);
    let array = [];
    arrOfcolor.map((item, index) => {
      array.push({ [locationIdArray[index]]: false });
    });
    visibledata?.forEach((item) => {
      // if (item?.actual === true) {
      arrOfcolor[item?.location - 1] = item?.actual;
      // }
    });
    arrOfcolor.map((value, index) => {
      array.map((obj) => {
        if (Object.keys(obj)[0] == index + 1) {
          obj[index + 1] = value === 0 ? false : value;
        }
      });
    });
    let updateArry = array.map((obj) => {
      return obj[Object.keys(obj)[0]];
    });

    setCheckColor1(updateArry);
  }, [visibledata, locationIdArray]);

  return (
    <>
      {loading ? (
        <div className="skeleton-div">
          <Skeleton style={{ height: "10rem", marginTop: "1rem" }} />
        </div>
      ) : (
        <Accordion style={{ width: "100%", overflow: "auto" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Container Milestone</Typography>
          </AccordionSummary>
          {options.length ? (
            <AccordionDetails>
              <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Container
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={valueOptions}
                  label="Select Container"
                  onChange={(e) => {
                    setvalueOptions(e.target.value);
                    dataToShow(e.target.value);
                  }}
                >
                  {options.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item}
                        onChange={() => handleChange()}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <div className="milestone-incoming d-flex">
                <div
                  className="milestone-buttons d-flex flex-column "
                  style={{
                    marginTop: "40px",
                    paddingTop: "3.25rem",
                    height: "220px",
                    width: "100%",
                    overflow: "auto",
                  }}
                >
                  {locationIdArray.map((item, index) => {
                    console.log("items", index, item);
                    return (
                      <>
                        <div style={{ marginTop: "2px" }}>
                          <div
                            className="d-flex align-items-center"
                            style={{ marginTop: "-60px" }}
                          >
                            <CheckCircleRoundedIcon
                              color={checkColor1[index] ? "success" : "grey"}
                            />
                            <div
                              className="milestone-card"
                              style={{
                                boxShadow:
                                  "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
                                borderRadius: " 12px",
                                padding: "12px 24px",
                                display: "flex",
                                justifyContent: " center",
                                alignItems: " flex-start",
                                flexDirection: "column",
                                height: "205px",
                                width: "92%",
                                backgroundColor: "#F5F5F5",
                                overflowY: "auto",
                              }}
                            >
                              <div
                                key={index}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div className="d-flex flex-column gap-2">
                                  <div>
                                    <div
                                      style={{
                                        color: "rgb(101, 148, 147)",
                                        fontSize: "var(--fs-mid)",
                                      }}
                                    >
                                      {/* {item?.country ?? null} */}
                                      {locationDetails[item - 1]?.country ??
                                        null}
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      className="d-flex flex-column"
                                      style={{ fontSize: "var(--fs-small)" }}
                                    >
                                      {visibledata?.length > 0 &&
                                        visibledata?.map((obj, ind) => {
                                          // --------------------------------------------------------------------------
                                          if (
                                            obj.location ===
                                            locationDetails[item - 1]?.id
                                          )
                                            return (
                                              <React.Fragment key={ind}>
                                                <div className="d-flex gap-4">
                                                  <div>
                                                    <div>
                                                      {DateFormat(
                                                        obj?.date ?? null
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex flex-column">
                                                      {obj?.description ?? ""}
                                                    </div>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* side bar  code */}
                          {checkColor1[index] ? (
                            <div
                              style={{
                                width: "0px",
                                height: "185px",
                                marginLeft: "11px",
                                border: "2px solid #2e7d32",
                                marginTop: "-84px",
                                display:
                                  index < checkColor1.length - 1
                                    ? "block"
                                    : "none",
                              }}
                            ></div>
                          ) : (
                            <div
                              style={{
                                width: "0px",
                                height: "130px",
                                marginLeft: "11px",
                                border: "2px solid grey",
                                marginTop: "-58px",
                                display:
                                  index < checkColor1.length - 1
                                    ? "block"
                                    : "none",
                              }}
                            ></div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </AccordionDetails>
          ) : (
            <div className="d-flex justify-content-center align-items-center mb-3">
              No data found!
            </div>
          )}
        </Accordion>
      )}
      {/* {loading ? (
        <div className="skeleton-div">
          <Skeleton style={{ height: "10rem", marginTop: "1rem" }} />
        </div>
      ) : (
        <Accordion expanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Container Milestone</Typography>
          </AccordionSummary>
          {options.length ? (
            <AccordionDetails>
              <FormControl fullWidth sx={{ marginBottom: "2rem" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Container
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={valueOptions}
                  label="Select container"
                  onChange={(e) => {
                    setvalueOptions(e.target.value);
                    dataToShow(e.target.value);
                  }}
                >
                  {options.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <div className="milestone-incoming d-flex">
                <div
                  className="milestone-buttons d-flex flex-column "
                  style={{ marginTop: "40px", paddingTop: "1.25rem" }}
                >
                  {visibledata.map((item, index) => {
                    return (
                      <>
                        <div style={{ marginTop: "2px" }}>
                          <div
                            className="d-flex align-items-center"
                            style={{ marginTop: "-60px" }}
                          >
                            <CheckCircleRoundedIcon
                              color={dateCheck ? "success" : "grey"}
                            />
                            <div
                              style={{
                                boxShadow:
                                  "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
                                borderRadius: " 12px",
                                padding: "12px 24px",
                                display: "flex",
                                justifyContent: " center",
                                alignItems: " flex-start",
                                flexDirection: "column",
                                height: "140px",
                                width: "321px",
                                backgroundColor: "#F5F5F5",
                                overflowY: "auto",
                              }}
                            >
                              <div
                                key={index}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div className="d-flex flex-column gap-2">
                                  <div>
                                    <div
                                      style={{
                                        color: "rgb(101, 148, 147)",
                                        fontSize: "var(--fs-mid)",
                                      }}
                                    >
                                      {item?.country}
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      className="d-flex flex-column"
                                      style={{ fontSize: "var(--fs-small)" }}
                                    >
                                      {item.milestonecollection.length &&
                                        item.milestonecollection.map(
                                          (i, ind) => {
                                            return (
                                              <React.Fragment key={ind}>
                                                <div className="d-flex gap-4">
                                                  <div>
                                                    <div>
                                                      {DateFormat(
                                                        i?.date ?? null
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex flex-column">
                                                      {i?.description ?? ""}
                                                    </div>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {index !==
                          containerMilestoneData[options]?.length - 1 ? (
                            <div
                              style={{
                                width: "0px",
                                height: "130px",
                                marginLeft: "11px",
                                border: "1px solid #2e7d32",
                                marginTop: "-58px",
                              }}
                            ></div>
                          ) : null}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </AccordionDetails>
          ) : (
            <div className="d-flex justify-content-center align-items-center mb-3">
              No data found!
            </div>
          )}
        </Accordion>
      )} */}
    </>
  );
};

export default MajorMilestone;
