export const Routeconstant = {
  // Shipment Details without Login
  SHIPMENTDETAILS: "/shipment/details/:id",
  // Auth
  LOGIN: "/login",
  RESETPASSWORD: `/reset-password/:id`,
  FORGETPASSWORD: "/forget-password",

  // RMS
  RMS: "/rms",
  CREATERMS: "/createrms",
  CREATERMSLIST: "/createrms/list",
  DOCUMENTRMS: "/documentrms",
  CARGORATES: "/cargorates",
  RMSREPORT: "/rmsreport",

  //Shipment Listing
  SHIPMENT_LISTING: "/shipment/listing",
  SHIPMENT_LISTING_DETAILS: "/shipment/listing/details",
  DETAILS: "details",
  ROUTING: "routing",
  CARGO_DETAILS: "cargodetails",
  DOCUMENTATION: "documentation",

  //Shipment Analyics
  SHIPMENT_ANALYTICS: "/shipment/analytics",

  //Order Listing
  // ORDER_LISTING: "/order/listing",
  // ORDER_DETAILS: "details",
  // ORDER_ROUTING: "routing",
  // ORDER_CARGO_DETAILS: "cargodetails",
  // ORDER_DOCUMENTATION: "documentation",

  //Order Analyics
  // ORDER_ANALYTICS: "/order/analytics",

  //Enquiry
  ENQUIRY: "/enquiry",

  // Statement of Account
  STATEMENT: "/statement",

  // User Logs
  USER_LOGS: "/user",
  //  Landing
  LANDING_PAGES: "/",
};
