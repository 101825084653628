import { useState, useEffect } from "react";
import { styled, Button } from "@mui/material";
import React from "react";
import { basename } from "../../Config";
import { AuthContext } from "../../store/context/AuthProvider";
import urlResolver from "../../utils/urlResolver";
const RmsButton = styled(Button)(({ theme }) => ({
  // Custom styles for the button
}));

const CreateEnquiry = (props) => {
  const context = React.useContext(AuthContext);
  const [activeButton, setActiveButton] = useState(0);

  console.log(props.data);

  const handleButtonClick = (index, item) => {
    setActiveButton(index);
    context.setButtonActive({ Code: item.Code, Description: item.Description });

    props.setFormdropData1({
      transportType: "",
      movementType: "",
      cargoType: "",
      incoType: "",
    });
    props.setFormOriginData({
      originCountry: null,
      originPort: null,
      originPin: "",
      destCountry: null,
      destPort: null,
      destPin: "",
      remarks: "",
    });
    console.log("context.buttonActive", context.buttonActive);
  };

  return (
    <div
      className=" d-flex justify-content-around align-item-center"
      style={{ width: "100%", flexWrap: "wrap" }}
    >
      {props.data.map((item, index) => {
        console.log("ghjgjh", index);
        return (
          <Button
            key={index}
            style={{
              backgroundColor:
                activeButton === index ? props.bluecolor : props.widecolor,
              color: activeButton === index ? props.widecolor : props.bluecolor,
              // Additional styles for the button
              minWidth: "100px",
              height: "50px",
              marginLeft: "15px",
              marginTop: "5px",
              borderRadius: "48px",
            }}
            onClick={() => handleButtonClick(index, item)}
          >
            <span style={{ fontSize: "var(--fs-lg)" }}>{item.Description}</span>

            {activeButton === index ? (
              <img
                src={urlResolver(`assets/${item.blueimg}`)}
                style={{ width: "20px", height: "20px" }}
                alt="air"
              />
            ) : (
              <img
                src={urlResolver(`assets/${item.wideimg}`)}
                style={{ width: "20px", height: "20px" }}
                alt="air"
              />
            )}
          </Button>
        );
      })}
    </div>
  );
};

export default CreateEnquiry;
