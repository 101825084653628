import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";
import instance from "../../../../../api/Interceptor";
import { API_PATH } from "../../../../../constant/apiPath";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
const YearFilter = ({
  yearList,
  setYearList,
  filterOptions,
  setFilterOptions,
  yearListCopy,
  setYearListCopy,
}) => {
  const [open, setOpen] = useState(true);
  const filterYearList = (val) => {
    if (val === "") {
      setYearList(yearListCopy);
      return;
    }
    let temp = yearListCopy.filter((item) => {
      return (item.Year + "").includes("" + val);
    });
    setYearList(temp);
  };
  const yearvalue = useRef("");
  console.log(yearList, "dd1");
  return (
    <div
      style={{
        padding: "2%",
      }}
    >
      <div
        style={{
          border: "0.1rem solid var(--color-primary)",
          borderRadius: "5px",
          padding: "2%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Year</Typography>
        <IconButton onClick={() => setOpen(!open)}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
      {open ? (
        <>
          <div
            style={{
              borderRadius: "7px",
              backgroundColor: "var(--color-miscellaneous-6)",
              marginTop: "2%",
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <input
              type="text"
              value={yearvalue.current}
              style={{
                border: "none",
                width: "80%",
                backgroundColor: "var(--color-miscellaneous-6)",
              }}
              onChange={(event) => {
                const inputValue = event?.target?.value ?? "";
                const numericValue = inputValue.replace(/\D/g, "");
                yearvalue.current = numericValue.slice(0, 4);
                filterYearList(numericValue.slice(0, 4));
                console.log(numericValue, "dd");
              }}
            />
          </div>
          <div
            style={{
              maxHeight: "25vh",
              overflowY: "auto",
            }}
          >
            <FormGroup
              style={{
                padding: "3%",
                overflowY: "auto",
              }}
            >
              {/* {yearList.map((year) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={false}
                        // onChange={handleChange}
                        color="success"
                        name={year.Year}
                        // name="1"
                      />
                    }
                    label={year.Year}
                  />
                );
              })} */}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={filterOptions.filterYear}
                name="radio-buttons-group"
                onChange={(e) => {
                  setFilterOptions({
                    ...filterOptions,
                    filterYear: e.target.value,
                  });
                }}
              >
                {yearList.map((eachYear) => {
                  return (
                    <FormControlLabel
                      control={<Radio value={eachYear.Year} />}
                      label={eachYear.Year}
                    />
                  );
                })}
              </RadioGroup>
            </FormGroup>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default YearFilter;
