import React, { useEffect } from "react";
import { basename } from "../../Config";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Card.css";
import { Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import { DateFormat } from "../../utils/moment";
import { Box, styled } from "@mui/material";
import { useState } from "react";
import urlResolver from "../../utils/urlResolver";
import { useContext } from "react";
import { AuthContext } from "../../store/context/AuthProvider";
const Vertical = styled(Box)(({ theme }) => ({
  height: "6rem",
  width: "0px ",
  border: "10.2px solid #286085",
  borderRadius: "5px",
  borderStyle: "ridge grey",
  transform: "scaleX(0.2)",
}));

const Card = ({
  baseroute,
  data,
  markFavourite,
  setCheckActive,
  index,
  checkActive,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(AuthContext)
  console.log("contextdata",context)

  const [loadingStar, setLoadingStar] = useState(false);

  return (
    <div>
      {/* <div className="card-div p-2 mb-2" > */}
      <div
        className={`card-div p-2 mb-2 ${
          checkActive === index ? "border-card-light" : ""
        }`}
      >
        <div className="mb-2 div-data">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              {location.pathname.includes(Routeconstant.SHIPMENT_LISTING) ? (
                <p className="m-0 num-data" title={data?.ShipmentId ?? "N/A"}>
                  {data?.ShipmentId ?? "N/A"}
                </p>
              ) : location.pathname.includes(Routeconstant.ORDER_LISTING) ? (
                <p
                  className="m-0 num-data"
                  title={`${data?.OrderNumber ?? "N/A"}/${
                    data?.OrderNumberSplit ?? "N/A"
                  }`}
                >
                  {`${data?.OrderNumber ?? "N/A"}/${
                    data?.OrderNumberSplit ?? "N/A"
                  }`}
                </p>
              ) : null}
            </div>
            <div className="d-flex justify-content-center gap-2 align-items-center">
              <ul className="mb-0 result-div">
                {data.ShipmentStatus === 1 ? (
                  <li>Pre-Loaded</li>
                ) : data.ShipmentStatus === 2 ? (
                  <li>In-Transit</li>
                ) : data.ShipmentStatus === 3 ? (
                  <li>Arrived</li>
                ) : data.ShipmentStatus === 4 ? (
                  <li>Archived</li>
                ) : null}
              </ul>
              {data.IsFavourite ? (
                loadingStar ? (
                  <img
                    style={{ cursor: "pointer" }}
                    className={"img-star flash"}
                    src={urlResolver("assets/coloredStar.svg")}
                  />
                ) : (
                  <img
                    style={{ cursor: "pointer" }}
                    className={`img-star`}
                    src={urlResolver("assets/coloredStar.svg")}
                    onClick={() => {
                      markFavourite(data?.ShipmentId, false, setLoadingStar);
                    }}
                  />
                )
              ) : loadingStar ? (
                <img
                  style={{ cursor: "pointer" }}
                  className={`img-star flash`}
                  src={urlResolver("assets/star.svg")}
                />
              ) : (
                <img
                  style={{ cursor: "pointer" }}
                  className={`img-star`}
                  src={urlResolver("assets/star.svg")}
                  onClick={() => {
                    markFavourite(data?.ShipmentId, true, setLoadingStar);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-around align-items-center">
          <div
            className="d-flex flex-column 
           
            align-items-end"
            style={{
              height: "8rem",
              justifyContent: "space-between",
            }}
          >
            <p className=" text-origin">Origin</p>
            <p className=" m-0 text-origin">Destination</p>
          </div>
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-23%",
                left: "5px",
              }}
            >
              <svg width="10" height="12" viewBox="0 0 23 25" fill="none">
                <ellipse
                  cx="11.5"
                  cy="12.5"
                  rx="11.5"
                  ry="12.5"
                  fill="#286085"
                />
              </svg>
            </div>

            {/* <img className="pl-3 pr-3" src={`${basename}/assets/line.svg")} /> */}
            <Vertical />
            <div
              style={{
                position: "absolute",
                top: "94%",
                left: "5px",
              }}
            >
              <svg width="10" height="12" viewBox="0 0 23 25" fill="none">
                <ellipse
                  cx="11.5"
                  cy="12.5"
                  rx="11.5"
                  ry="12.5"
                  fill="#286085"
                />
              </svg>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-grow-1">
            <div
              style={{
                height: "8rem",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div>
                <p
                  className="m-0 origin-num word-ellipsis"
                  title={data?.OriginPortName ?? "N/A"}
                >
                  {data?.OriginPortName ?? "N/A"}
                </p>
                <p
                  className="m-0 origin-date "
                  title={DateFormat(data?.ETDDate ?? null)}
                >
                  {DateFormat(data?.ETDDate ?? null)}
                </p>
                <p
                  className=" origin-date "
                  title={data?.ConsignorName ?? "N/A"}
                >
                  {data?.ConsignorName ?? "N/A"}
                </p>
              </div>
              <div>
                <p
                  className="m-0 origin-num word-ellipsis"
                  title={data?.DestinationPortName ?? ""}
                >
                  {" "}
                  {data?.DestinationPortName ?? ""}
                </p>
                <p
                  className="m-0 origin-date"
                  title={DateFormat(data?.ETADate ?? null)}
                >
                  {DateFormat(data?.ETADate ?? null)}
                </p>
                <p
                  className=" origin-date"
                  title={data?.ConsigneeName ?? "N/A"}
                >
                  {data?.ConsigneeName ?? "N/A"}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {data?.TransportMode === "SEA" ? (
                <img
                  className="img-transport"
                  src={urlResolver("assets/ship.svg")}
                />
              ) : data?.TransportMode === "AIR" ? (
                <img
                  className="img-transport"
                  src={urlResolver("assets/Air.svg")}
                />
              ) : data?.TransportMode === "RAI" ? (
                <img
                  className="img-transport"
                  src={urlResolver("assets/rail.svg")}
                />
              ) : data?.TransportMode === "ROA" ? (
                <img
                  className="img-transport"
                  src={urlResolver("assets/road.svg")}
                />
              ) : null}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <p
                  className="origin-date fw-bold "
                  style={{ marginRight: "50px" }}
                  title={data?.ContainerMode}
                >
                  {data?.ContainerMode}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Accordion className="accordian-item">
            <AccordionSummary
              className="icon-expand-div"
              expandIcon={<ExpandMoreIcon className="icon-expand" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="typo-text">Show details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                className="d-flex justify-content-between align-items-center details-div"
                onClick={() => {
                  context.setShipmentId(data?.ShipmentId)
                  setCheckActive(index);
                  navigate(`${baseroute}/${Routeconstant.DETAILS}`, {
                    state: {
                      ShipmentId: data.ShipmentId,
                      data: data,
                      TransportMode: data.TransportMode,
                      // HouseBillNumber: data.HouseBillNumber,
                    },
                  });
                }}
              >
                <p className="m-0 py-1 item-detail">Basic details</p>
                <img src={urlResolver("assets/side_arrow.svg")} />
              </div>
              <div
                className="d-flex justify-content-between align-items-center details-div "
                onClick={() => {
                  context.setShipmentId(data?.ShipmentId)
                  setCheckActive(index);
                  navigate(`${baseroute}/${Routeconstant.ROUTING}`, {
                    state: {
                      ShipmentId: data.ShipmentId,
                      // ShipmentTransportMode: data.TransportMode,
                      data: data,
                      // HouseBillNumber: data.HouseBillNumber,
                    },
                  });
                }}
              >
                <p className="m-0 py-1 item-detail">Route details</p>
                <img src={urlResolver("assets/side_arrow.svg")} alt="" />
              </div>
              <div
                className="d-flex justify-content-between align-items-center details-div"
                onClick={() => {
                  context.setShipmentId(data?.ShipmentId)
                  setCheckActive(index);
                  navigate(`${baseroute}/${Routeconstant.CARGO_DETAILS}`, {
                    state: {
                      ShipmentId: data.ShipmentId,
                      TransportMode: data.TransportMode,
                      HouseBillNumber: data.HouseBillNumber,
                      data: data,
                    },
                  });
                }}
              >
                <p className="m-0 py-1 item-detail">Cargo Details</p>
                <img src={urlResolver("assets/side_arrow.svg")} alt="" />
              </div>
              <div
                className="d-flex justify-content-between align-items-center details-div"
                onClick={() => {
                  setCheckActive(index);
                  context.setShipmentId(data?.ShipmentId)
                  navigate(`${baseroute}/${Routeconstant.DOCUMENTATION}`, {
                    state: {
                      ShipmentId: data.ShipmentId,
                      TransportMode: data.TransportMode,
                      HouseBillNumber: data.HouseBillNumber,
                    },
                  });
                }}
              >
                <p className="m-0 py-1 item-detail">Documentation</p>
                <img src={urlResolver("assets/side_arrow.svg")} alt="" />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Card;
