import React, { useState, useEffect } from "react";
import CreateEnquiry from "./CreateEnquiry";
import instance from "../../api/Interceptor";
import { AuthContext } from "../../store/context/AuthProvider";

const HeaderEnquiryBtn = (props) => {
  const context = React.useContext(AuthContext);
  const [headerdata, setHeaderdata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTransportmodes();
  }, []);
  const getTransportmodes = () => {
    setLoading(true);
    instance
      .get("api/v1/master/transportmodes")
      .then((res) => {
        context.setButtonActive(res.data[0].Code);
        const updatedData = res.data.map((obj) => {
          if (obj.Code === "AIR") {
            return {
              ...obj,
              blueimg: "AIR-WHITE.svg",
              wideimg: "Air.svg",
            };
          } else if (obj.Code === "COU") {
            return {
              ...obj,
              blueimg: "COURIER-WHITE.svg",
              wideimg: "courier.svg",
            };
          } else if (obj.Code === "SEA") {
            return {
              ...obj,
              blueimg: "SHIP-WHITE.svg",
              wideimg: "ship.svg",
            };
          } else if (obj.Code === "ROA") {
            return {
              ...obj,
              blueimg: "ROAD-WHITE.svg",
              wideimg: "road.svg",
            };
          } else if (obj.Code === "RAI") {
            return {
              ...obj,
              blueimg: "RAIL-WHITE.svg",
              wideimg: "rail.svg",
            };
          } else {
            return obj;
          }
        });
        setHeaderdata(updatedData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  console.log("dataaa", props.data);
  return (
    <div className="d-flex justify-content-between createbtn-section">
      <CreateEnquiry
        setFormdropData1={props.setFormdropData1}
        formdropData1={props.formdropData1}
        formOriginData={props.formOriginData}
        setFormOriginData={props.setFormOriginData}
        data={headerdata}
        widecolor="var(--color-miscellaneous-6)"
        bluecolor="var(--color-secondary-dark)"
        className="rms-btn-text"
        style={{
          padding: "0.2rem 3rem",
        }}
      />
    </div>
  );
};

export default HeaderEnquiryBtn;
