import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { basename } from "../../../Config";
import "../shipment.css";
import { AuthContext } from "../../../store/context/AuthProvider";
import { DateFormat } from "../../../utils/moment";
import BtnOutline from "../../../components/button/BtnOutline";
import { Box, CircularProgress, Skeleton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { toast } from "react-hot-toast";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import urlResolver from "../../../utils/urlResolver";
const ShipmentListTable = ({
  sortColumnCopy,
  getShipmentCollection,
  currentPage,
  setCurrentPage,
  setLoading,
  loading,
  totalCount,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
  setStatus,
  loadingOnSearch,
}) => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const markFavourite = (shipmentId, isFavourite) => {
    instance
      .post(
        `${API_PATH.MARK_FAVOURITE}ShipmentId=${shipmentId}&IsFavourite=${isFavourite}`
      )
      .then((res) => {
        toast.success(res?.message?.Message ?? "");
        getShipmentCollection(0, "");
      })
      .catch((err) => {
        toast.success(err ?? "");
      });
  };

  return (
    <>
      <div className="tableWrap">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="font-table"></th>
              <th scope="col" className="font-table">
                Shipment No.
                {sortColumn !== "JS_ShipmentId" || sortColumn === "" ? (
                  <ImportExportIcon
                    onClick={() => {
                      sortColumnCopy.current = "JS_ShipmentId";
                      setSortColumn("JS_ShipmentId");
                      setSortDirection("Asc");
                      setStatus("");
                      getShipmentCollection(0, "");
                    }}
                  />
                ) : (
                  <>
                    {sortDirection === "Asc" ? (
                      <ArrowDownwardIcon
                        onClick={() => {
                          console.log(">>>> in des");
                          setSortDirection("Desc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        onClick={() => {
                          console.log(">>>> in asc");
                          setSortDirection("Asc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    )}
                  </>
                )}
              </th>
              <th scope="col" className="font-table">
                Volume
              </th>
              <th scope="col" className="font-table">
                Weight
              </th>
              <th scope="col" className="font-table">
                Transport Mode
              </th>
              <th scope="col" className="font-table">
                Container Type
              </th>
              <th scope="col" className="font-table">
                Container No.
              </th>
              <th scope="col" className="font-table">
                PO/Order Ref No.
                {sortColumn !== "JS_OrderRef" || sortColumn === "" ? (
                  <ImportExportIcon
                    onClick={() => {
                      sortColumnCopy.current = "JS_OrderRef";
                      setSortColumn("JS_OrderRef");
                      setSortDirection("Asc");
                      setStatus("");
                      getShipmentCollection(0, "");
                    }}
                  />
                ) : (
                  <>
                    {sortDirection === "Asc" ? (
                      <ArrowDownwardIcon
                        onClick={() => {
                          setSortDirection("Desc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        onClick={() => {
                          setSortDirection("Asc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    )}
                  </>
                )}
              </th>
              <th scope="col" className="font-table">
                Master Bill No.
              </th>
              <th scope="col" className="font-table">
                House Bill No.
              </th>
              <th scope="col" className="font-table">
                Consignor
                {sortColumn !== "JS_ConsignorName" || sortColumn === "" ? (
                  <ImportExportIcon
                    onClick={() => {
                      sortColumnCopy.current = "JS_ConsignorName";
                      setSortColumn("JS_ConsignorName");
                      setSortDirection("Asc");
                      setStatus("");
                      getShipmentCollection(0, "");
                    }}
                  />
                ) : (
                  <>
                    {sortDirection === "Asc" ? (
                      <ArrowDownwardIcon
                        onClick={() => {
                          setSortDirection("Desc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        onClick={() => {
                          setSortDirection("Asc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    )}
                  </>
                )}
              </th>
              <th scope="col" className="font-table">
                Consignee{" "}
                {sortColumn !== "JS_ConsigneeName" || sortColumn === "" ? (
                  <ImportExportIcon
                    onClick={() => {
                      sortColumnCopy.current = "JS_ConsigneeName";
                      setSortColumn("JS_ConsigneeName");
                      setSortDirection("Asc");
                      setStatus("");
                      getShipmentCollection(0, "");
                    }}
                  />
                ) : (
                  <>
                    {sortDirection === "Asc" ? (
                      <ArrowDownwardIcon
                        onClick={() => {
                          setSortDirection("Desc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        onClick={() => {
                          setSortDirection("Asc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    )}
                  </>
                )}{" "}
              </th>
              <th scope="col" className="font-table">
                Origin Port{" "}
                {sortColumn !== "JS_OriginPortName" || sortColumn === "" ? (
                  <ImportExportIcon
                    onClick={() => {
                      sortColumnCopy.current = "JS_OriginPortName";
                      setSortColumn("JS_OriginPortName");
                      setSortDirection("Asc");
                      setStatus("");
                      getShipmentCollection(0, "");
                    }}
                  />
                ) : (
                  <>
                    {sortDirection === "Asc" ? (
                      <ArrowDownwardIcon
                        onClick={() => {
                          setSortDirection("Desc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        onClick={() => {
                          setSortDirection("Asc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    )}
                  </>
                )}
              </th>
              <th scope="col" className="font-table">
                Destination Port{" "}
                {sortColumn !== "JS_DestinationPortName" ||
                sortColumn === "" ? (
                  <ImportExportIcon
                    onClick={() => {
                      sortColumnCopy.current = "JS_DestinationPortName";
                      setSortColumn("JS_DestinationPortName");
                      setSortDirection("Asc");
                      setStatus("");
                      getShipmentCollection(0, "");
                    }}
                  />
                ) : (
                  <>
                    {sortDirection === "Asc" ? (
                      <ArrowDownwardIcon
                        onClick={() => {
                          setSortDirection("Desc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        onClick={() => {
                          setSortDirection("Asc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    )}
                  </>
                )}
              </th>
              <th scope="col" className="font-table">
                ETA{" "}
                {sortColumn !== "JS_ETADate" || sortColumn === "" ? (
                  <ImportExportIcon
                    onClick={() => {
                      sortColumnCopy.current = "JS_ETADate";
                      setSortColumn("JS_ETADate");
                      setSortDirection("Asc");
                      setStatus("");
                      getShipmentCollection(0, "");
                    }}
                  />
                ) : (
                  <>
                    {sortDirection === "Asc" ? (
                      <ArrowDownwardIcon
                        onClick={() => {
                          setSortDirection("Desc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        onClick={() => {
                          setSortDirection("Asc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    )}
                  </>
                )}
              </th>
              <th scope="col" className="font-table">
                ETD{" "}
                {sortColumn !== "JS_ETDDate" || sortColumn === "" ? (
                  <ImportExportIcon
                    onClick={() => {
                      sortColumnCopy.current = "JS_ETDDate";
                      setSortColumn("JS_ETDDate");
                      setSortDirection("Asc");
                      setStatus("");
                      getShipmentCollection(0, "");
                    }}
                  />
                ) : (
                  <>
                    {sortDirection === "Asc" ? (
                      <ArrowDownwardIcon
                        onClick={() => {
                          setSortDirection("Desc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    ) : (
                      <ArrowUpwardIcon
                        onClick={() => {
                          setSortDirection("Asc");
                          setStatus("");
                          getShipmentCollection(0, "");
                        }}
                      />
                    )}
                  </>
                )}
              </th>
              <th scope="col" className="font-table">
                INCO Terms{" "}
              </th>
              <th scope="col" className="font-table">
                Release Terms
              </th>
              <th scope="col" className="font-table">
                Carrier
              </th>
              <th scope="col" className="font-table">
                Status
              </th>
            </tr>
          </thead>
          {loading.initial || loadingOnSearch ? null : (
            <tbody>
              {context.shipmentCollection.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div
                        className="d-flex justify-content-center align-items-center h-100 w-100"
                        style={{ cursor: "pointer" }}
                      >
                        {item.IsFavourite ? (
                          <img
                            src={urlResolver("assets/coloredStar.svg")}
                            onClick={() =>
                              markFavourite(item.ShipmentId, false)
                            }
                          />
                        ) : (
                          <img
                            src={urlResolver("assets/star.svg")}
                            onClick={() => markFavourite(item.ShipmentId, true)}
                          />
                        )}
                      </div>
                    </td>
                    <td
                      title={item?.ShipmentId ?? "N/A"}
                      style={{
                        cursor: "pointer",
                        color: "var(--color-secondary-dark)",
                      }}
                      onClick={() => {
                        context.setShipmentId(item.ShipmentId);
                        navigate(Routeconstant.DETAILS, {
                          state: {
                            ShipmentId: item.ShipmentId,
                            ETA: item.ETADate,
                            ETD: item.ETDDate,
                            ORIGIN: item.OriginPortName,
                            DESTINATION: item.DestinationPortName,
                            data: item,
                            TransportMode: item.TransportMode,
                            HouseBillNumber: item.HouseBillNumber,
                          },
                        });
                      }}
                    >
                      <span>{item?.ShipmentId ?? null}</span>
                    </td>
                    <td className="ellipsis" title={item?.TotalVolume ?? null}>
                      <span>
                        {item?.TotalVolume ?? null}{" "}
                        {item?.TotalVolumeUQ ?? null}
                      </span>
                    </td>
                    <td className="ellipsis" title={item?.TotalWeight ?? null}>
                      <span>
                        {item?.TotalWeight ?? null}
                        {item?.TotalWeightUQ ?? null}
                      </span>
                    </td>
                    <td
                      className="ellipsis"
                      title={item?.TransportMode ?? null}
                    >
                      <span>{item?.TransportMode ?? null}</span>
                    </td>

                    <td
                      className="ellipsis"
                      title={item?.ContainerMode ?? null}
                    >
                      <span>{item?.ContainerMode ?? null}</span>
                    </td>

                    <td className="ellipsis">
                      <span>
                        {item.ContainerNumbers.length ? (
                          <>
                            {item.ContainerNumbers.map((i, ind) => {
                              return (
                                <>
                                  {item.ContainerNumbers.length - 1 === ind ? (
                                    <>{`${i}`}</>
                                  ) : (
                                    <>{`${i},`}</>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>{null}</>
                        )}
                      </span>
                    </td>
                    <td className="ellipsis" title={item?.OrderRef ?? null}>
                      <span>{item?.OrderRef ?? null}</span>
                    </td>
                    <td className="ellipsis">
                      <span>
                        {item.MasterBillNumber.length ? (
                          <>
                            {item.MasterBillNumber.map((i, ind) => {
                              return (
                                <>
                                  {item.MasterBillNumber.length - 1 === ind ? (
                                    <>{`${i}`}</>
                                  ) : (
                                    <>{`${i},`}</>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>{null}</>
                        )}
                      </span>
                    </td>
                    <td
                      className="ellipsis"
                      title={item?.HouseBillNumber ?? null}
                    >
                      <span>{item?.HouseBillNumber ?? null}</span>
                    </td>
                    <td
                      className="ellipsis"
                      title={item?.ConsignorName ?? null}
                    >
                      <span>{item?.ConsignorName ?? null}</span>
                    </td>
                    <td
                      className="ellipsis"
                      title={item?.ConsigneeName ?? null}
                    >
                      <span>{item?.ConsigneeName ?? null}</span>
                    </td>
                    <td
                      className="ellipsis"
                      title={item?.OriginPortName ?? null}
                    >
                      <span>{item?.OriginPortName ?? null}</span>
                    </td>
                    <td
                      className="ellipsis"
                      title={item?.DestinationPortName ?? null}
                    >
                      <span>{item?.DestinationPortName ?? null}</span>
                    </td>
                    <td
                      className="ellipsis"
                      title={DateFormat(item?.ETADate ?? null)}
                    >
                      <span> {DateFormat(item?.ETADate ?? null)}</span>
                    </td>
                    <td
                      className="ellipsis"
                      title={DateFormat(item?.ETDDate ?? null)}
                    >
                      <span>{DateFormat(item?.ETDDate ?? null)}</span>
                    </td>
                    <td className="ellipsis" title={item?.INCOTerm ?? null}>
                      <span> {item?.INCOTerm ?? null}</span>
                    </td>
                    <td className="ellipsis" title={item?.ReleaseType ?? null}>
                      <span>{item?.ReleaseType ?? null}</span>
                    </td>
                    <td className="ellipsis" title={item?.CarrierName ?? null}>
                      <span>{item?.CarrierName ?? null}</span>
                    </td>
                    <td
                      className="ellipsis"
                      title={item?.ShipmentStatusDescription ?? null}
                    >
                      <span>
                        {item?.ShipmentStatusDescription === "Loaded"
                          ? "In-transit"
                          : item?.ShipmentStatusDescription === "PreLoaded"
                          ? "Pre-Loaded"
                          : item?.ShipmentStatusDescription !== null
                          ? item?.ShipmentStatusDescription
                          : null}
                      </span>{" "}
                    </td>
                    {/* <td>
                      <div
                        className="d-flex justify-content-center align-items-center h-100 w-100"
                        style={{ cursor: "pointer" }}
                      >
                        <img src={urlResolver("assets/dotted.svg")} />
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading.initial || loadingOnSearch ? (
          <div className="skeleton-div">
            <Skeleton style={{ height: "47rem" }} />
          </div>
        ) : null}
        {context.shipmentCollection.length === 0 && (
          <div style={{ textAlign: "center" }}>No Data Found</div>
        )}
      </div>

      <div className="mt-2">
        {context.shipmentCollection.length !== totalCount ? (
          <BtnOutline
            title={
              loading.loadmore ? (
                <CircularProgress
                  size="1rem"
                  style={{ color: "var(--color-miscellaneous-4)" }}
                />
              ) : (
                "Show More"
              )
            }
            onClick={() => {
              const pagenumber = currentPage + 1;
              getShipmentCollection(pagenumber, "");
              setCurrentPage(pagenumber);
              context.setListSize(pagenumber);
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default ShipmentListTable;
