export const API_PATH = {
  // Shipment module
  GET_SHIPMENT_COLLECTION: "api/v1/Shipment/GetShipmentForListView",
  GET_SHIPMENT_DETAILS: "api/v1/Shipment/GetShipmentDetails",
  GET_CARGO_DETAILS: "api/v1/Shipment/GetShipmentPackingDetails",
  GET_DOCUMENT_DETAILS: "api/v1/Shipment/GetShipmentDocumentDetails",
  GET_OPTIONS_FOR_DOC_UPLOAD: "api/v1/Shipment/GetUploadDocumentTypeList",
  UPLOAD_DOC_SHIPMENT: "api/v1/Shipment/PostUploadDocument",
  MARK_FAVOURITE: "api/v1/Shipment/PostFavouriteShipment?",
  GET_SHIPMENT_DOCUMENT_LIST: "api/v1/Shipment/GetShipmentDocumentList",
  GET_CW1_MILESTONE: "api/v1/Shipment/GetShipmentMilestoneDetails",
  GET_TRANSPORT_DETAILS: "api/v1/Shipment/GetShipmentTransportDetails",

  GET_CONTAINER_MILESTONE_ROUTES:
    "api/v1/Location/GetContainerMilestoneRoutesVersion2",
  EXPORT_TO_EXCEL_SHIPMENT: "api/v1/Shipment/GetShipmentReportListView",
  GET_ANALYTICS_SHIPMENT_MAP:
    "api/v1/analytic/shipmentcountcountrywisebydirection",
  GET_WHATSAPP_NUMBER: "api/v1/Shipment/GetWhatsAppContacts",
  GET_EMAIL_CONTACTS: "api/v1/Shipment/GetEmailContacts",
  GET_ANALYTICS_PREPAID_COLLECT:
    "api/v1/analytic/shipmentcountprepaidvscollect",
  GET_ANALYTICS_SHIPMENTCOUNTONMODE: "api/v1/analytic/shipmentcountonmode",
  GET_ANALYTICS_SHIPMENTCOUNTBYTIME: "api/v1/analytic/shipmentcountbytime",
  GET_ANALYTICS_SHIPMENT_TANNAGE: "api/v1/analytic/shipmenttunnage",
  GET_ANALYTICS_SHIPMENTCOUNTBYCARRIER:
    "api/v1/analytic/shipmentcountbycarrier",
  GET_CAREER_AVERAGE_TRANSIT_TIME: "api/v1/analytic/carrieraveragetransitetime",
  GET_DOCUMENT_DATA: "api/v1/Shipment/GetDocumentData",
  GET_ROUTE_LOCATION: "api/v1/Location/ShipmentRoutes",
  POST_SHIPMENT_SUBSCRIPTION: "api/v1/Shipment/PostShipmentSubscription",
  TANNAGE_BY_CARRIER: "api/v1/analytic/shipmenttunnagebycarrier",
  // KPIAnalytics
  GET_HBLISSUE_VS_SHIPPEDON: "api/v1/analytic/hblshpkpi",
  GET_THRESHOLD_SETTINGS: "api/v1/analytic/getthreshholdsettings",
  SET_THRESHOLD_SETTINGS: "api/v1/analytic/savethreshholdsettings",
  GET_PICKUP_DEPARTURE: "api/v1/analytic/ActualPickupDepartureKpi",
  GET_PICKUP_ARRIVAL: "api/v1/analytic/ActualDeliveryArrivalKpi",
  // Dashboard
  GET_FAVORITESHIPMENT: "api/v1/Shipment/GetFavouriteShipment",
  GET_UPCOMINGSHIPMENT: "api/v1/Shipment/GetUpcomingShipmentList",
  GET_MAPLOCATIONDATA: "api/v1/Shipment/GetUpcomingShipmentCounts",
  GET_OUTSTANDINGAMOUNT: "api/v1/Shipment/GetOutstandingDueAmount",
  GET_ETA_EXPECTATION: "api/v1/Shipment/ETAException",
  GET_ETD_EXPECTATION: "api/v1/Shipment/ETDException",

  //Order Module
  GET_ORDER_COLLECTION: "api/v1/Order/GetOrderCollection",

  // statement of Account module
  GET_TRANSACTION_COLLECTION: "api/v1/Transaction/GetTransactionCollection",
  GET_AGING_DATA: "api/v1/Transaction/GetTransactionAgingCollection",
  DOWNLOAD_DATA: "api/v1/Transaction/GetTransactionDocumentData",
  GET_TRANSACTION_DATA: "api/v1/Transaction/GetTransactionReportData",

  //User
  FORGET_PASSWORD: "UserAPI/api/login/ResetLink?UserId=",
  LOGIN: "UserAPI/api/login/Login",
  RESET_PASSWORD: "UserAPI/api/login/ResetPassword",
  GET_ALL_USER: "UserAPI/api/user/Get-All-User",
  CHANGE_PASSWORD: "UserAPI/api/login/ChangePassword",
  RESETPASSWORD_LINKACTIVATE: "UserAPI/api/login/VerifyLink?Id=",
  CHANGE_LOGIN_STATUS: "UserAPI/api/user/loginaccessbility",
  GET_MODULE: "UserAPI/api/user/moduleaccesbility",

  // Notification
  NOTIFICATION_API: "api/v1/Shipment/GetEventList",
  //  Public tracker
  GET_SHIPMENT_WITHOUT_LOGIN: "api/v1/Shipment/GetShipmentWithoutLogin",
  // master
  GET_YEARS_LIST: "api/v1/master/years",
  GET_TRANSPORT_MODES: "api/v1/master/transportmodes",
  GET_LISTOF_COUNTRY: "api/v1/master/unlocos",
  GET_CARRIER_CODES: "api/v1/master/carriercodes",
};
