export const genrateDownloadLink = (filename) => {
  const extension = filename.split(".").pop().toUpperCase();

  let url = "";
  switch (extension) {
    case "PDF":
      url = "data:application/pdf;base64,";
      break;
    case "XLSX":
      url =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      break;
    case "XLS":
      url = "data:application/vnd.ms-excel;base64,";
  }
  return url;
};
