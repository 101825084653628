import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import instance from "../../api/Interceptor";
import Autocomplete from "@mui/material/Autocomplete";
import { AuthContext } from "../../store/context/AuthProvider";
import EnquiryList from "./EnquiryList";

const DetailsEnquiry = ({
  formdropData1,
  setFormdropData1,
  formOriginData,
  setFormOriginData,
  mailId,
  setMailid,
  handleRmsDataChange1,
  rows,
  setRows,
  validationErrors,
  setValidationErrors,
  setOpenBackDrop,
}) => {
  const context = React.useContext(AuthContext);
  const savedTransportData = JSON.parse(localStorage.getItem("TransportData"));
  const navigate = useNavigate();
  const [transport, setTransport] = useState([]);
  const [movement, setMovement] = useState([]);
  const [cargo, setCargo] = useState([]);
  const [inco, setInco] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryPort, setCountryPort] = useState([]);
  const [countryDestPort, setCountryDestPort] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rmsFormData, setRmsFormData] = useState({
    InquiryNo: "",
    CWBookingNumber: "",
    Transportmode: "",
    Containertype: "",
    MovementType: "",
    Incoterm: "",
    Carotype: "",
    IMOClass: "",
    HSCode: "",
    UnNumber: "",
    OriginPort: "",
    destinationport: "",
    POReceipt: "",
    FinalDeliveryUnlocoCode: "",
    ShipperInvoiceNumber: "",
    Goodsvalue: "",
    Cargoreadydate: "",
    AdditionalServices: "",
    Goodsdescription: "",
    StatusCode: "",
    StatusDescription: "",
    JobType: "",
    CreatedUserName: "",
    ConsignorCode: "",
    ConsignorName: "",
    ConsigneeCode: "",
    ConsigneeName: "",
    NotifypartyCode: "",
    NotifypartyName: "",
    PaymentTerm: "",
    ClientNotes: "",
    CarrierCode: "",
    CarrierName: "",
    validityDate: "",
  });
  const [isOriginPortFilled, setIsOriginPortFilled] = useState(false);
  const [isDestPortFilled, setIsDestPortFilled] = useState(false);
  const isCountryNameSelected = !!formOriginData.originCountry;
  const isDestCountryNameSelected = !!formOriginData.destCountry;
  const isDoorToDoor = formdropData1.movementType === "Door to Door";
  const isPortToDoor = formdropData1.movementType === "Port to Door";
  const isDoorToPort = formdropData1.movementType === "Door to port";
  const [selectedOriginCountry, setSelectedOriginCountry] = useState(null);
  const [selectedOriginPort, setSelectedOriginPort] = useState(null);
  const [selectedDestCountry, setSelectedDestCountry] = useState(null);
  const [selectedDestPort, setSelectedDestPort] = useState(null);

  const mailIdOption = {
    USA: "US.Pricing@1upcargo.com",
    Canada: "CA.Pricing@1upcargo.com",
    Australia: "AU.Pricing@1upcargo.com",
    "South Africa": "ZA.Pricing@1upcargo.com",
    "New Zealand": "NZ.Pricing@1upcargo.com",
    // "India":"libin@sflhub.com",
  };

  const handleDropdownOriginChange = (event) => {
    const { name, value } = event.target;
    const selectedCountry = country.find((c) => c.CountryCode === value);
    setFormOriginData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    setFormdropData1((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "transportType") {
      localStorage.setItem("TransportData", JSON.stringify(value));
    } // Set the selected value in local storage
    console.log("Selected value:", value);
  };

  //       transportType:'',
  //       movementType:'',
  //       incoType:'',
  //       originCountry:'',
  //       originPort:'',
  //       destCountry:'',
  //       destPort:''
  //     });
  //     let errors = {};
  //     // Perform input validations
  //     if (formdropData1.transportType.trim() === '') {
  //       errors.transportType = 'Please provide Transport Type.';
  //     }
  //     if (formdropData1.movementType.trim() === '') {
  //       errors.movementType = 'Please provide Movement Type.';
  //     }
  //     if (formdropData1.incoType.trim() === '') {
  //       errors.incoType = 'Please provide Inco Type.';
  //     }
  //     if (formOriginData.originCountry === null || formOriginData.originCountry === '') {
  //       errors.originCountry = 'Please provide Country Name.';
  //     }
  //     if (formOriginData.originPort === null || formOriginData.originPort === '') {
  //       errors.originPort = 'Please provide Country Port.';
  //     }
  //     if (formOriginData.destCountry === null || formOriginData.destCountry === '') {
  //       errors.destCountry = 'Please provide Country Name.';
  //     }
  //     if (formOriginData.destPort === null || formOriginData.destPort === '') {
  //       errors.destPort = 'Please provide Country Port.';
  //     }

  //     if (Object.keys(errors).length > 0) {
  //       // Display validation errors
  //       setValidationErrors(errors);
  //       return;
  //     }
  //     console.log("formdropData1.transportType",formdropData1.transportType);

  //     const body={

  //       "Inquiry_CargoDetails": [
  //           {
  //               "containerType": "",
  //               "dimensionUnit": "mm",
  //               "height": 2323,
  //               "length": 10,
  //               "pkgType": "Cartons",
  //               "quantity": 45,
  //               "stackable": "No",
  //               "weight": 258,
  //               "weightUnit": "kg",
  //               "width": 232
  //           }
  //       ],
  //       "Inquiry_CargoType": formdropData1.cargoType,
  //       "Inquiry_CargoTypeDescription": "",
  //       "Inquiry_CompanyType": "Individual",
  //       "Inquiry_ContactName": "Test user",
  //       "Inquiry_DestinationCountry": formOriginData.destCountry,
  //       "Inquiry_DestinationPinCode": formOriginData.destPin,
  //       "Inquiry_DestinationPort": formOriginData.destPort,
  //       "Inquiry_EMail": "ankit@sflhub.com",
  //       "Inquiry_IncoTerm": formdropData1.incoType,
  //       "Inquiry_MovementType": formdropData1.movementType,
  //       "Inquiry_Organization": "This is Teri Company",
  //       "Inquiry_OriginCountry": formOriginData.originCountry,
  //       "Inquiry_OriginPinCode": formOriginData.originPin,
  //       "Inquiry_OriginPort": formOriginData.originPort,
  //       "Inquiry_Phone": "75894121919",
  //       "Inquiry_Remarks": formOriginData.remarks,
  //       "Inquiry_TransportMode": context.buttonActive
  //   }

  //  instance
  //    .post("api/v1/Inquiry/Create",body)
  //      .then((res) => {
  //       //  context.setRmsinquiry(res.data[0]?.InquiryNumber);
  //        console.log("resp",res.data)
  //        alert("successs")
  //       // navigate(Routeconstant.CREATERMSLIST);
  //      })
  //      .catch((err) => {
  //        console.log(err);
  //      })
  //      .finally(() => {
  //        setLoading(false);
  //      });
  // };
  useEffect(() => {
    setIsOriginPortFilled(!!formOriginData.originPort); // !! converts to boolean
  }, [formOriginData.originPort]);

  useEffect(() => {
    setIsDestPortFilled(!!formOriginData.destPort);
  }, [formOriginData.destPort]);

  useEffect(() => {
    console.log("", context);
    getTransporttype();
  }, [context.buttonActive]);

  useEffect(() => {
    getcountryPort();
    setSelectedOriginCountry(
      country.find((c) => c.CountryCode === formOriginData.originCountry) ||
        null
    );
    setSelectedOriginPort(
      country.find((c) => c.CountryCode === formOriginData.OriginPort) || null
    );
  }, [formOriginData.originCountry, formOriginData.originCountryName, country]);

  useEffect(() => {
    getdestcountryPort();
    setSelectedDestCountry(
      country.find((c) => c.CountryCode === formOriginData.destCountry) || null
    );
    setSelectedDestPort(
      country.find((c) => c.CountryCode === formOriginData.destPort) || null
    );
  }, [formOriginData.destCountry, country]);

  useEffect(() => {
    getTransporttype();
    getMovementtype();
    getcargotype();
    getincotype();
    getcountry();
  }, []);
  const getTransporttype = () => {
    setLoading(true);
    const body = {
      TransportMode: context?.buttonActive?.Code ?? "AIR",
    };
    instance
      .post("api/v1/master/GetContainerTypeInquiry", body)
      .then((res) => {
        setTransport(res.data ?? []);
        console.log("transport", transport);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getMovementtype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetMovementType")
      .then((res) => {
        console.log("movement", res.data);
        setMovement(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getcargotype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetCargoType")
      .then((res) => {
        //  console.log("movement",res.data);
        setCargo(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getincotype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetIncoTerm")
      .then((res) => {
        //  console.log("movement",res.data);
        setInco(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getcountry = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetCountries")
      .then((res) => {
        console.log("country", res.data);
        setCountry(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getcountryPort = () => {
    console.log("in api formOriginData", formOriginData);
    setLoading(true);
    const body = {
      CountryCode: formOriginData.originCountry,
    };
    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        console.log("setCountryPort", res.data);
        setCountryPort(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getdestcountryPort = () => {
    setLoading(true);
    const body = {
      CountryCode: formOriginData.destCountry,
    };
    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        console.log("setCountryPort", res.data);
        setCountryDestPort(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {/* --FIRST-- */}
      <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
        <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
          <FormControl
            fullWidth
            sx={{ paddingBottom: "1rem" }}
            className="details-section"
          >
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: " var(--color-mile)" }}
            >
              TRANSPORT TYPE
            </InputLabel>
            <Select
              name="transportType"
              value={formdropData1.transportType}
              onChange={handleDropdownChange}
              fullWidth
              className="MuiSelect-outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              default={20}
              label="TRANSPORT TYPE"
              IconComponent={KeyboardArrowDownOutlinedIcon}
              variant="outlined"
            >
              <MenuItem value={0} disabled>
                Select
              </MenuItem>
              {transport.map((trans, index) => {
                const value = `${trans.Description} (${trans.Code})`;
                return (
                  <MenuItem value={value}>
                    {trans.Description} ({trans.Code})
                  </MenuItem>
                );
              })}
            </Select>
            {validationErrors.transportType && (
              <div className="error">{validationErrors.transportType}</div>
            )}
          </FormControl>
          {/* {console.log("formdropData1", formdropData1.transportType)} */}
          {/* / */}
          <br />
          <FormControl
            fullWidth
            sx={{ paddingBottom: "1rem" }}
            className="details-section"
          >
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: " var(--color-mile)" }}
            >
              MOVEMENT TYPE
            </InputLabel>
            <Select
              name="movementType"
              value={formdropData1.movementType}
              onChange={handleDropdownChange}
              fullWidth
              className="MuiSelect-outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              default={20}
              label="MOVEMENT TYPE"
              IconComponent={KeyboardArrowDownOutlinedIcon}
              variant="outlined"
            >
              <MenuItem value={0} disabled>
                Select
              </MenuItem>
              {movement.map((movem, index) => {
                const value = `${movem.Description} (${movem.Code})`;
                return (
                  <MenuItem value={movem.Description}>
                    {movem.Description}
                  </MenuItem>
                );
              })}
            </Select>
            {validationErrors.movementType && (
              <div className="error">{validationErrors.movementType}</div>
            )}
          </FormControl>

          {/* /////////// */}
          <br />
          <FormControl
            fullWidth
            sx={{ paddingBottom: "1rem" }}
            className="details-section"
          >
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: " var(--color-mile)" }}
            >
              CARGO TYPE
            </InputLabel>
            <Select
              fullWidth
              name="cargoType"
              value={formdropData1.cargoType}
              onChange={handleDropdownChange}
              className="MuiSelect-outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              default={20}
              label="CARGO TYPEE"
              IconComponent={KeyboardArrowDownOutlinedIcon}
              variant="outlined"
            >
              <MenuItem value={0} disabled>
                Select
              </MenuItem>
              {cargo.map((cargo, indexC) => {
                return (
                  <MenuItem value={cargo.Description}>
                    {cargo.Description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* /////////////// */}

          <br />
          <FormControl
            fullWidth
            sx={{ paddingBottom: "1rem" }}
            className="details-section"
          >
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: " var(--color-mile)" }}
            >
              INCO TERMS
            </InputLabel>
            <Select
              fullWidth
              name="incoType"
              value={formdropData1.incoType}
              onChange={handleDropdownChange}
              className="MuiSelect-outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              default={20}
              label="INCO TERMS"
              IconComponent={KeyboardArrowDownOutlinedIcon}
              variant="outlined"
            >
              <MenuItem value={0} disabled>
                Select
              </MenuItem>
              {inco.map((inco, indexI) => {
                const value = `${inco.Description} (${inco.Code})`;
                return (
                  <MenuItem value={value}>
                    {inco.Description} ({inco.Code})
                  </MenuItem>
                );
              })}
            </Select>
            {validationErrors.incoType && (
              <div className="error">{validationErrors.incoType}</div>
            )}
          </FormControl>
        </Paper>
      </Grid>
      {/* --MID-- */}
      <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
        <Paper
          elevation={4}
          sx={{ padding: "24px", height: "100%", width: "100%" }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
              <Typography className="pb-3">
                <LocationOnIcon color="primary" />
                <span className="card-rms">Origin</span>
              </Typography>
              <FormControl
                fullWidth
                sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                className="details-section"
              >
                <InputLabel style={{ color: "var(--color-mile)" }}></InputLabel>
                <Autocomplete
                  id="COUNTRYNAME"
                  options={country}
                  getOptionLabel={(option) => option.CountryName}
                  value={selectedOriginCountry}
                  onChange={(event, newValue) => {
                    setSelectedOriginCountry(newValue);
                    setFormOriginData({
                      ...formOriginData,
                      originCountry: newValue?.CountryCode,
                      originCountryName: newValue?.CountryName,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="COUNTRY NAME" />
                  )}
                />
                {validationErrors.originCountry && (
                  <div className="error1">{validationErrors.originCountry}</div>
                )}
              </FormControl>
              <FormControl
                fullWidth
                sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                className="details-section"
              >
                <InputLabel style={{ color: "var(--color-mile)" }}></InputLabel>
                <Autocomplete
                  id="COUNTRYPORT"
                  options={countryPort}
                  getOptionLabel={(option) => option.Name}
                  value={selectedOriginPort}
                  onChange={(event, newValue) => {
                    setSelectedOriginPort(newValue);
                    setFormOriginData({
                      ...formOriginData,
                      originPort: newValue?.Code,
                      originPortName: newValue?.Name,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="COUNTRY PORT" />
                  )}
                  disabled={!isCountryNameSelected}
                />
                {validationErrors.originPort && (
                  <div className="error1">{validationErrors.originPort}</div>
                )}
              </FormControl>
              {(isDoorToDoor || isDoorToPort) && (
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    label="COUNTRY PIN/ZIP CODE"
                    variant="outlined"
                    name="originPin"
                    value={formOriginData.originPin}
                    onChange={(e) =>
                      handleDropdownOriginChange(e, e.target.value, "originPin")
                    }
                    disabled={!isOriginPortFilled}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
              <Typography className="pb-3">
                <LocationOnIcon color="primary" />
                <span className="card-rms">Destination</span>
              </Typography>
              <FormControl
                fullWidth
                sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                className="details-section"
              >
                <InputLabel style={{ color: "var(--color-mile)" }}></InputLabel>
                <Autocomplete
                  id="DESTCOUNTRYNAME"
                  options={country}
                  getOptionLabel={(option) => option.CountryName}
                  value={selectedDestCountry}
                  onChange={(event, newValue) => {
                    setSelectedDestCountry(newValue);
                    setFormOriginData({
                      ...formOriginData,
                      destCountry: newValue?.CountryCode,
                      destCountryName: newValue?.CountryName,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="COUNTRY NAME" />
                  )}
                />
                {validationErrors.destCountry && (
                  <div className="error1">{validationErrors.destCountry}</div>
                )}
              </FormControl>
              <FormControl
                fullWidth
                sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                className="details-section"
              >
                <InputLabel style={{ color: "var(--color-mile)" }}></InputLabel>
                <Autocomplete
                  id="DESTCOUNTRYPORT"
                  options={countryDestPort}
                  getOptionLabel={(option) => option.Name}
                  value={selectedDestPort}
                  onChange={(event, newValue) => {
                    setSelectedDestPort(newValue);
                    setFormOriginData({
                      ...formOriginData,
                      destPort: newValue?.Code,
                      destPortName: newValue?.Name,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="COUNTRY PORT" />
                  )}
                  disabled={!isDestCountryNameSelected}
                />
                {validationErrors.destPort && (
                  <div className="error1">{validationErrors.destPort}</div>
                )}
              </FormControl>
              {(isDoorToDoor || isPortToDoor) && (
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    label="COUNTRY PIN/ZIP CODE"
                    variant="outlined"
                    name="destPin"
                    value={formOriginData.destPin}
                    disabled={!isDestPortFilled}
                    onChange={(e) =>
                      handleDropdownOriginChange(e, e.target.value, "destPin")
                    }
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* --LAST-- */}
      <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
        <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
          <Typography className="pb-3"></Typography>

          <FormControl
            fullWidth
            sx={{ paddingBottom: "1rem" }}
            className="details-section"
          >
            <TextField
              name="remarks"
              value={formOriginData.remarks}
              onChange={handleDropdownOriginChange}
              label="REMARKS"
              variant="outlined"
              multiline
              rows={5}
            />
            {validationErrors.remarks && (
              <div className="error">{validationErrors.remarks}</div>
            )}
          </FormControl>
        </Paper>
      </Grid>
      {/* --BOTTOM-- */}
      {formdropData1.transportType.length !== 0 ? (
        <Grid item lg={12} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper
            elevation={4}
            sx={{ padding: "24px", height: "100%", width: "100%" }}
          >
            <Grid container spacing={2}>
              <EnquiryList rows={rows} setRows={setRows} />
            </Grid>
            <Grid container style={{ display: "flex", justifyContent: "end" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <FormControl
                  sx={{
                    paddingBottom: "1rem",
                    marginBottom: "-1rem",
                    marginRight: "0.5rem",
                    width: "15em",
                  }}
                  className="details-section"
                >
                  <InputLabel
                    style={{ color: "var(--color-mile)" }}
                  ></InputLabel>
                  <Autocomplete
                    id="email"
                    options={Object.keys(mailIdOption)}
                    getOptionLabel={(option) => option}
                    value={mailId?.split("/")?.at(0) ?? ""}
                    onChange={(event) => {
                      // console.log(event.target.outerText,"mailseleted");
                      const value = event.target.outerText;
                      if (value) setMailid(`${value}/${mailIdOption[value]}`);
                      else setMailid(` / `);
                      setFormOriginData({
                        ...formOriginData,
                        email: event.target.outerText,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="MAIL TO" />
                    )}
                  />
                  {validationErrors.email && (
                    <div className="error1">{validationErrors.email}</div>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                style={{ display: "flex", justifyContent: "start" }}
              >
                <Button
                  sx={{
                    borderRadius: "10%",
                    width: "10%",
                    height: "3rem",
                    // padding: "0.7em",
                    backgroundColor: "var(--color-secondary-dark)",
                    color: "var(--color-white)",
                    "&:hover": {
                      backgroundColor: "var(--color-secondary-dark)", // Keep the same background color on hover
                    },
                  }}
                  onClick={() => {
                    console.log("contex", context);
                    handleRmsDataChange1();
                  }}
                  disabled={loading}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default DetailsEnquiry;
